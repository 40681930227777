import styles from "./Transactions.module.sass";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { numberWithCommas, getTimestamp, getToDay, yesterday } from "../../../utils.js";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Details from "./Details";
import Form from "../../../components/Form";
import { DatePicker, Space, ConfigProvider, message, Empty } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

// date
import { transactions } from "../../../mocks/transactions";
import OrderList from "..";
import utils from "../../../common";

const intervals = ["今日", "昨日", "自定义时间"];
const navigation = ["商户订单"];


const Transactions = ({ className }) => {
  const { RangePicker } = DatePicker;
  const [sorting, setSorting] = useState(intervals[0]);
  const [search, setSearch] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [itemDetail, setitemDetail] = useState({});
  const [orderList,setOrder] = useState([]);
  const [dateString,setDateString] = useState([]);


  useEffect(()=>{
    if(sorting != '自定义时间' || dateString.length > 1){
      api_orderList()
    }
  },[sorting, activeIndex, dateString])

  const dataSortChange = (item, idx)=>{
    setSorting(item)
  };

  const setActiveIndexChange = (item, idx)=>{
    setActiveIndex(idx)
  };

  const api_orderList = ()=>{
    let params = {
      order_no: search || '',
      status:'',//订单状态：0:未支付，3:已完成，4:已取消
      // current_page: 1,
      page_size:500,
      next:'', 
      previous:'',
      service_type: 1 
    }
    
    if(sorting == '今日'){
      params.start_time = getToDay() + '00:00:00'
      params.end_time = getToDay() + '23:59:59'
    }else if(sorting == '昨日'){
      params.start_time = yesterday() + '00:00:00'
      params.end_time = yesterday() + '23:59:59'
    }else{
      params.start_time = dateString[0] + ":00"
      params.end_time = dateString[1] + ":00"
    }

    if(activeIndex == 0){  // 0 商户订单、 1 服务商订单
      params.merchant_code = ''
    }else{
      params.agent_no = ''
    }

    params = utils.dealElement(params);
    window.api('order.orderList',params).then((res)=>{
      if(res.service_status == 'S'){
        setOrder([...res.orders])
      }
    }).catch(()=>{
      setOrder([])
    })
  };

  const modalDetail = (item, index) =>{
    setitemDetail(item)
    setVisibleModal(true)
  }

  // 自定义时间
  const onChangeTimes = (value, dateString) => {
    setDateString(dateString)
  };

  const onOkTimes = (value) => {
  };
  

  return (
    <Card
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      title="订单列表"
      classTitle={cn("title-blue", styles.title)}
      head={
        <>
          <Form
              className={styles.form}
              value={search}
              setValue={(e)=>{setSearch(e)}}
              onSubmit={() => api_orderList()}
              placeholder="搜索订单号"
              type="text"
              name="search"
              icon="search"
          />

          <div className={styles.times}>
            {
              sorting == '自定义时间' ? (
                
                  <Space direction="vertical" size={12}>
                    <ConfigProvider locale={locale}>
                      <RangePicker
                        showTime={{
                          format: 'HH:mm',
                        }}
                        placeholder={['选择起始日期时间','选择截止日期时间']}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChangeTimes}
                        onOk={onOkTimes}
                      />
                    </ConfigProvider>
                  </Space>
              ) : null
            }
          </div>

          <Dropdown
            className={styles.dropdown} 
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={(item, idx) => dataSortChange(item, idx)}
            options={intervals}
            small
          />

          {/* <button className={cn("button-small", styles.button)}>
            Download SCV
          </button> */}

          {/* <div className={styles.nav}>
            {navigation.map((x, index) => (
              <button
                className={cn(styles.button, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndexChange(x, index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div> */}
        </>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>购买商品/订单编号</div>
            <div className={styles.col}>订单状态</div>
            <div className={styles.col}>购买商户</div>
            <div className={styles.col}>数量</div>
            <div className={styles.col}>订单金额</div>
            <div className={styles.col}>服务商收益</div>
            <div className={styles.col}>平台收益</div>
            <div className={styles.col}>订单操作</div>
          </div>
          {
            orderList.length > 0 ?
            (
              orderList.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    <div className={styles.colflex}>
                      <img
                        srcSet={`${x.order_details[0].goods_pic} 2x`}
                        src={x.order_details[0].goods_pic}
                        alt="产品图片"
                      />
                      <div className={styles.lefttxt}>
                          {x.order_details[0].goods_name}
                        <div className={styles.label}>{x.order_details[0].order_no}</div>
                      </div>
                    </div>

                    
                  </div>
                  <div className={styles.col}>
                    {/* <div className={styles.details}>
                      <div className={styles.product}>{x.product}</div>
                      <div className={styles.invoice}>{x.invoice}</div>
                    </div> */}
                    {x.status == 0 ? (
                      <div
                        className={cn(
                          { "status-red-dark": x.status === 0 },
                          styles.status
                        )}
                      >
                        待付款
                      </div>
                    ) : x.status == 4 ?
                    (
                      <div
                        className={cn(
                          { "status-yellow": x.status === 4 },
                          styles.status
                        )}
                      >
                      已取消
                      </div>
                    ):x.status == 3 ? (
                      <div
                        className={cn(
                          { "status-green-dark": x.status === 3 },
                          styles.status
                        )}
                      >
                      已完成
                      </div>
                    ):null}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.product}>{x.merchant_name || '--'}</div>
                    <div className={styles.invoice}>{x.merchant_code || '--'}</div>
                  </div>
                  <div className={styles.col}>
                   {x.order_details[0].goods_qty}
                  </div>
                  <div className={styles.col}>
                    ¥{x.total_amt}
                  </div>
                  <div className={styles.col}>
                    ¥{x.order_extend?.agentEarning || 0}
                  </div>
                  <div className={styles.col}>
                    ¥{x.order_extend?.platformEarning || 0}
                  </div>
                  <div className={styles.col}>
                    <button className={cn('button', styles.btnwh)} onClick={() => modalDetail( x, index )}>订单详情</button>
                  </div>
                </div>
              ))
            )
            :
            (
              <div className={styles.empty}>
                <Empty />
              </div>
            )
          }
        </div>
      </div>
      {/* <div className={styles.foot}>
        <button className={cn("button-stroke button-small", styles.button)}>
          <Loader className={styles.loader} />
          <span>Load more</span>
        </button>
      </div> */}
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details item={itemDetail} itemDetail={itemDetail?.order_details ? itemDetail?.order_details[0] : {}} onClose={() => setVisibleModal(false)}/>
      </Modal>
    </Card>
  );
};

export default Transactions;
