import React, { useEffect, useRef, useState } from "react";
import styles from "./SystemList.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Tags from "../../../components/Tags";
import Modal from "../../../components/Modal";
import Details from "./Details"
import utils from "../../../common";

const SystemList = ({ systemList }) => {
  const [search, setSearch] = useState(""); // 搜索
  const [activeItem, setActiveItem] = useState({}); // 选中修改的一级分类item
  const [visibleModal, setVisibleModal] = useState(false); // 子分类弹窗显/隐
  const [systemTags, setSystemTags] = useState(systemList); // 服务市场一级分类data
  const [listTags, setSystemListTags] = useState([]); // 全部分类

  useEffect(() => {
    setSystemTags([...systemList])
  }, [systemList]);

  useEffect(() => {
    // api_getProdCategory()
  }, []);
  
  // 搜索框输入
  const setSearchChange = (e) => {
    setSearch(e);
  };
  // 获取分类
  const getCateList = () => {
    api_getProdCategory()
  };
  // 操作自组件完成后更新分类
  const handleChange = (e,tag) => {
    api_getProdCategory()
    if(tag == 'systemTag' && !isSameArr(e, systemTags)){
      setSystemTags([...e])
    }
  }

  const isSameArr = (arr,brr)=>{
    if(arr?.length == brr.length){
      for(var i= 0;i<arr.length ; i++){
        if(JSON.stringify(arr[i]) !== JSON.stringify(brr[i])){
          return false;
        }
      }
    }else{
      return false
    }
    return true;
  }

  // 获取分类
  const api_getProdCategory = ()=>{
    let params = {
      service_type:1,
      goods_category_name: search || '',
    }
    params = utils.dealElement(params);
    window.api('goods.getcategorylist', params).then(res => {
    if(res.success){
      // 赋值全部分类
      setSystemListTags(res.goods_category_list) 
      // 过滤出服务市场一级分类
      let cate_system = res.goods_category_list.filter((item)=>item.goods_category_hierarchy == 1 && item.service_type == 1 )
      cate_system.forEach((e)=>{
          e.text = e.goods_category_name
          e.id = e.id.toString()
      })
      // 服务市场分类赋值
      setSystemTags([ ...cate_system ])
    }
    }).catch(() => {
        
    })
  }
  // 标签点击调用
  const updateChange = (i, tag) => {
    setActiveItem({
      idx:i,
      id:systemTags[i].id,
      tagName:systemTags[i].goods_category_name,
      superior_id: systemTags[i].superior_id,
      service_type:systemTags[i].service_type,
      tag
    })
    setVisibleModal(true)
  }

  const editCate = (flag) => {
    setVisibleModal(false)
    if(flag){
      api_getProdCategory()
    }
  }

  return (
    <Card
      className={cn(styles.card)}
      classCardHead={styles.head}
      title="商品分类"
      classTitle={cn("title-blue", styles.title)}
      head={
        <>
          <Form
            className={(styles.form, styles.formwid)}
            value={search}
            setValue={(e) => setSearchChange(e)}
            onSubmit={() => getCateList()}
            placeholder="搜索分类"
            type="text"
            name="search"
            icon="search"
          />
        </>
      }
    >
      <div className={styles.tagBox} style={{background: '#fff'}}>
        <Tags tagList={systemTags} handleEmit={handleChange} tagFlag='systemTag' handleUpdate={updateChange} editable={false}/>
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => editCate()}
      >
        <Details onCloseTag={(flag) => editCate(flag)} tagItem={activeItem} tagList={listTags} getCateList={getCateList}/>
      </Modal>
    </Card>
  );
};

export default SystemList;
