import React, { useState } from "react";
import cn from "classnames";
import styles from "./Preview.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import { useHistory } from "react-router";

const Preview = ({ visible, defaultData }) => {
  const router = useHistory()
  const [yearsIdx, setyearsIdx] = useState(0);
  // const [detailPics, setDetailPics] = useState(defaultData.goods_detail_pics || '');
  let str = defaultData?.goods_detail_pics
  let arr = str?.split(',') || []

  const detailPics = [...arr]

  //取消
  const handleClose = ()=> {
    router.goBack(-1)
  }

  const htmldesp_deatail = (defaultData?.goods_details)?.replace(/<[^>]+>/g, '')
  const htmldesp_intro = (defaultData?.goods_intro)?.replace(/<[^>]+>/g, '')

  return (
    <div className={cn(styles.preview, { [styles.visible]: visible })}>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="商品预览"
        classTitle="title-blue"
        // head={
        //   <button className={styles.button}>
        //     <Icon name="expand" size="24" />
        //   </button>
        // }
      >
        <div className={styles.body}>
          <div className={styles.photo}>
            <img src={defaultData.goods_picture} alt="产品封面图片" />
          </div>
          <div className={styles.textmsg}>
            <div className={styles.line}>
              <div className={styles.title}>
                {defaultData.goods_name}
              </div>
              <div className={styles.price}>¥ <span>{defaultData.sale_price}</span></div>
            </div>
            { htmldesp_intro }
          </div>
          
          {
            defaultData.valid_days > 365 ? 
            (
              <div className={styles.years}>
                <div>年限</div>
                <div className={styles.yearsnum}>
                  {
                    [1,2,3].map((item,index)=>(
                      <div className={ yearsIdx == index ? styles.ynum_light : styles.ynum } key={index} onClick={()=>setyearsIdx(index)}>{item}年</div>
                    ))
                  }
                </div>
              </div>
            ) : null
          }
          
          <div className={styles.detailss}>
            <div className={styles.text}>
              <span>商品详情</span>
              {htmldesp_deatail}
              <div className={styles.imgbox}>
                  {
                    detailPics.map((item, idx) =>(
                      <img key={idx} className={styles.imgimg} src={item} alt="产品图片" />
                    ))
                  }
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.btns}>
          <button className={cn("button-stroke", styles.button)} onClick={() => handleClose()}>取消</button>
          <button className={cn("button", styles.button)} onClick={() => handleClose()}>确定</button>
        </div> */}
      </Card>
    </div>
  );
};

export default Preview;
