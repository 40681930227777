import React, { useEffect, useState } from "react";
import styles from "./NewProduct.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Modal from "../../components/Modal";
import Schedule from "../../components/Schedule";
import NameAndDescription from "./NameAndDescription";
import ImagesAndCTA from "./ImagesAndCTA";
import Price from "./Price";
import PriceControl from "./PriceControl";
import CategoryAndAttibutes from "./CategoryAndAttibutes";
import ProductFiles from "./ProductFiles";
import Discussion from "./Discussion";
import Preview from "./Preview";
import Panel from "./Panel";
import cn from "classnames";
import { useLocation, useHistory } from "react-router";
import { getUrlBase64 } from "../../utils";
import { Empty, message, Tabs } from "antd";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import Form from "../../components/Form";
import SelectPrice from "./SelectPrice";
import SelectOutlet from "./SelectOutlet";

const NewProduct = ({}) => {
  const router = useLocation()
  const routers = useHistory()
  const id = router.state ? router.state.id : '';
  const type = router.state ? router.state.type : '';  // type 1创建 2编辑 3预览
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [current,setCurrent] = useState('1');
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [isLoading,setLoading] = useState(true)
  const [goodsNo,setGoodsNo] = useState('');
  const [dataSource,setDataSource] = useState({});
  const [categoryList,setCategory] = useState([]);
  const [BrandList,setBrand] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const identity = window.localStorage.getItem('identity') // 登录者身份 0：平台 1：代理商 2：商户
  const [visiblePrice, setPriceModel] = useState(false) 
  const [showOutlet, setShowOutlet] = useState(false) 
  const [priceOptions, setPriceOptions] = useState('')
  const [selectOptions, setSelectOptions] = useState([])
  const [isHas, setIsHas] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0);
    if(type != 1){ // 2,3
      api_getProdDetail()
    } else { // 1
        api_getCodeNo()
        api_getProdCategory()
        api_getProdBrand()
      }
  },[id, type])
  //禁止点击
  const stopChange = () =>{
    
  }
  // 返回
  const handleClose = ()=>{
    routers.goBack(-1)
  }
  //获取商品详情
  const api_getProdDetail = ()=>{
    let params = { id :id }
    window.api( 'goods.getgoodsdetail' , params).then(res => {
      if(res.success){
        setDataSource({ ...res })
        if(type == 2){
          api_getProdCategory()
        }else{
          setLoading(false)
        }
      }else{
        setDataSource({})
      }
      
    }).catch(() => {
      setDataSource({})
    });
  }
  //获取一级分类
  const api_getProdCategory = ()=>{
    window.api('goods.getcategorylist', {service_type:1}).then(res => {
      if(res.success){
        setCategory([ ...res.goods_category_list ])
        api_getProdBrand()
      }
    }).catch(() => {
      setCategory([])
    })
  }
  //获取品牌
  const api_getProdBrand = ()=>{
    window.api('eps.getgoodsbrand', {}).then(res => {
      if(res.success){
        setBrand([ ...res.goods_brand_list ])
        setLoading(false)
      }
    }).catch(() => {
      setBrand([])
    })
  }
  //获取随机编码
  const api_getCodeNo = () => {
    window.api('goods.getbarno', {}).then(res => {
      if(res.success){
        let goods_bar_no = res.barNo || ''
        setGoodsNo(goods_bar_no)
        setLoading(false)
        return goods_bar_no
      }
    }).catch(() => {
    })
  }

  // 基本信息
  const getMsgInfo = (val)=> {
    setDataSource({...dataSource,...val})
  }

  //商品图+产品详情图
  const getPicFile = (val,flag)=>{
    if(flag == 'cut'){
      if(val.length > 0){
        dataSource.goods_intro_pics = val.toString()
      }else{
        dataSource.goods_intro_pics = dataSource.goods_intro_pics
      }
      // dataSource.goods_intro_pics = (dataSource.goods_intro_pics ? dataSource.goods_intro_pics + ',' : '' )  + val;
      // dataSource.goods_intro_pics = val
      setDataSource({...dataSource})
    }else{
      if(val.length > 0){
        dataSource.goods_detail_pics = val.toString()
      }else{
        dataSource.goods_detail_pics = dataSource.goods_detail_pics
      }
      // dataSource.goods_detail_pics = (dataSource.goods_detail_pics ? dataSource.goods_detail_pics + ',' : '' ) + val;
      // dataSource.goods_detail_pics = val
      setDataSource({...dataSource})
    }
  }
  //价格修改回调  商品分类回调
  const changeEmit = (data) => {
    let obj = {
      goods_brand_id : data.brand?.id,
      goods_brand_name : data.brand?.value,
      goods_category_id : data.smallType?.id ? data.smallType?.id : data.category?.id,
      goods_category_name : data.smallType?.value ? data.smallType?.value : data.category?.value,
      // goods_categorySmall_id : data.smallType?.id,
      // goods_categorySmall_name : data.smallType?.value
    }
    
    setDataSource({...dataSource,...data,...obj})
  }

  // 提交
  const api_submitGoodsDetail =( site )=>{
    // 默认写死参数
    let obj = {
      valid_days: 365,
      goods_property: 1,
      is_post: 1,
      self_support: 1,
      use_sn: 1,
      stock: dataSource.goods_num || 99999,
      flow_limit_amt:'0',
      service_type:1,
      merchant_show: 1,
      cost_price: (dataSource.pay_type == 3 || dataSource.cost_price) ? dataSource.cost_price : 0,
      sale_price: (dataSource.pay_type == 3 || dataSource.sale_price) ? dataSource.sale_price : 0,
    }, api = '' ;

    {/* site 商品状态（0：出售中；1：已下架） */}

    if( type == 1 || site == 1 ){
      obj ={
        ...obj,
        status:0
      }
    }
    
    {/* type （1：创建；2：编辑） */}
    let img = dataSource.goods_intro_pics
    let imgArr = img?.split(',')
    if(type == 1){ 
      api = 'goods.addgoods'
      obj = {
        ...obj,
        goods_bar_no : goodsNo,
        goods_pic: imgArr ? imgArr[0] : ''
      }
    }else if(type == 2){
      api = 'goods.modgoods'
      obj = {
        ...obj,
        goods_pic: imgArr ? imgArr[0] : ''
      }
    }
    window.api((api), {...dataSource,...obj}).then(res => {
      if(res.success){
        message.info('保存成功')
        routers.goBack(-1);
      }else{
        message.error('保存失败')
      }
    })
  }
  // 上下架
  const api_cancelGoodsDetail = ( site ) => {
    {/* 商品状态（0：出售中；1：已下架） */}
    if( type == 1 ){
      routers.goBack(-1)
       return false
    }
    
    let obj = {
      flow_limit_amt:0,
    }
    if( site == 0 ){
      obj ={
        status:1
      }
    }

    window.api(('goods.modgoods'), {...dataSource,...obj}).then(res => {
      if(res.success){
        message.info('保存成功')
        routers.goBack(-1);
      }else{
        message.error('保存失败')
      }
    })
  }

  // 代理商修改价格
  const api_agentModifyGoods = () => {
    let options = {
      goodsId: dataSource.id,
      salePrice: dataSource.sale_price,
    }
    window.api(('goods.agentModifyGoods'), options).then(res => {
      if(res.success){
        message.info('保存成功')
        routers.goBack(-1);
      }else{
        message.error('保存失败')
      }
    })
  }

  // 
  const pricsChangeEmit = (data) => {
    setPriceOptions(data)
  }
  //
  const selsectChangeEmit = (data) => {
    setSelectOptions(data)
  }

  //
  const initPrice = () => {
    if(dataSource.pay_type == 4){
      message.error('面议商品无法自定义价格！')
      return false
    }
    setShowOutlet(false)
    setIsHas(false)
    setPriceModel(true)
  }

  // 添加
  const addChangeReat = () => {
    let arr = []
    selectOptions.forEach((i)=>{
      arr.push(i.lable)
    })
    let options = {
      goodsId: dataSource.id,
      salePrice: priceOptions
    }
    if(current == 2){
      options.merchantCode = arr.toString()
    }else{
      options.storeCode = arr.toString()
    }
    window.api(('goods.agentModifyGoods'), options).then(res => {
      if(res.success){
        message.info('保存成功')
        setIsHas(true)
        setPriceModel(false)
      }else{
        message.error('保存失败')
        setIsHas(true)
      }
    })
  }

  return (
    <>
      {
        current == 1 ? (
          <>
            {
            dataSource ? 
            <>
              <div className={styles.row}>
                {
                  isLoading ? null : type == 1 || type == 2 || type == 3 ? 
                  (
                    <>
                      <div className={styles.col}>
                        <>
                          <div className={styles.topBox}>
                            <div className={styles.tabBox}>
                              <span className={styles.cardBox}></span>
                              <span className={current == 1 ? styles.tite : styles.none} onClick={()=>{setCurrent(1)}}>商品信息</span>
                              {
                                identity == 1 ? (
                                  <>
                                  <span className={current == 2 ? styles.tite : styles.none} onClick={()=>{setCurrent(2)}}>自定义总部价格</span>
                                  <span className={current == 3 ? styles.tite : styles.none} onClick={()=>{setCurrent(3)}}>自定义门店价格</span>
                                  </>
                                ) : null
                              }
                              
                            </div>
                            <div className={cn("button-stroke button-small", styles.button)} onClick={()=>handleClose()}>
                                <div className={styles.txthover}>
                                  <Icon name="arrow-left"  size="24" />
                                  <span>返回</span>
                                </div>
                            </div>
                          </div>
                          <NameAndDescription identity={identity} className={styles.card} submitMsgInfo={getMsgInfo}  defaultData={dataSource}/> 
                          
                          <ProductFiles identity={identity} className={styles.card} onChange={getPicFile} defaultData={dataSource}/>
                          
                          <Price identity={identity} className={styles.card} priceChangeEmit={changeEmit} defaultData={dataSource} type={type}/>

                          <CategoryAndAttibutes identity={identity} className={styles.card} categoryList={categoryList} BrandList={BrandList} categoryEmit={changeEmit} defaultData={dataSource}/>

                          {/* <ImagesAndCTA className={styles.card} />
                          <Discussion className={styles.card} /> */}

                          <TooltipGlodal />
                        </>
                      </div>
                      <div className={styles.col}>
                        {
                          id > 0 &&
                          <Preview
                            visible={visiblePreview}
                            defaultData={dataSource}
                            onClose={() => setVisiblePreview(false)}
                          />
                        }
                      </div>

                      {
                        type == 3 &&
                        <div className={styles.col_modal} onClick={() => stopChange()}>
                        
                        </div>
                      }
                    </>
                  ) : null
                }
                
              </div>
              {
                isLoading ? null : type == 1 || type == 2 || type == 3 ? 
                (
                  <Panel
                    identity={identity}
                    otherData={dataSource}
                    type={type}
                    status={dataSource.status}
                    onCancel={()=>api_cancelGoodsDetail(dataSource.status)}
                    onConfirm={()=>api_submitGoodsDetail(dataSource.status)}
                    handleClose={()=>handleClose()}
                    priceOnConfirm={()=>api_agentModifyGoods()}
                  />
                  
                ) : null
              }
              <TooltipGlodal />
            </>
            :
            <div className={styles.empty}>
                <Empty />
            </div>
          }
          </>
        ) : current == 2 ? (
          <div>
            <div className={styles.topBox}>
              <div className={styles.tabBox}>
                <span className={styles.cardBox}></span>
                <span className={current == 1 ? styles.tite : styles.none} onClick={()=>{setCurrent(1)}}>商品信息</span>
                <span className={current == 2 ? styles.tite : styles.none} onClick={()=>{setCurrent(2)}}>自定义总部价格</span>
                <span className={current == 3 ? styles.tite : styles.none} onClick={()=>{setCurrent(3)}}>自定义门店价格</span>
              </div>
              <div className={styles.formbox}>
                <Form
                    className={styles.form}
                    value={search}
                    setValue={(e)=>{setSearch(e)}}
                    onSubmit={() =>{setSearchValue(search)}}
                    placeholder="搜索商户总部"
                    type="text"
                    name="search"
                    icon="search"
                />
                <button className={cn("button", styles.addbutton) } onClick={() => {initPrice()}}>
                  <Icon name="add" size="24" />
                  <span>自定义价格</span>
                </button>
              </div>
            </div>
            <PriceControl isHas={isHas} modl={'shop'}  value={searchValue}  goodsId={dataSource.id} goodsNo={dataSource.goods_bar_no} goodsName={dataSource.goods_name}/>

            <Modal 
              outerClassName={styles.outer}
              visible={visiblePrice}
              onClose={() => setPriceModel(false)}
              isZzc={true}
            >
                <div className={styles.com_box}>
                  <SelectPrice onClose={() => setPriceModel(false)} modl={'shop'} handleOutlet={(e)=>{setShowOutlet(e)}} pricsChangeEmit={(e) => pricsChangeEmit(e)} />

                  {
                    showOutlet ? (
                      <SelectOutlet modl={'shop'} selsectChangeEmit={(e)=>{selsectChangeEmit(e)}}/>
                    ) : null
                  }

                  {
                    priceOptions > 0 && (selectOptions || []).length > 0 ? (
                      <button className={cn("button", styles.button3)} onClick={()=>addChangeReat()}>
                        添加
                      </button>
                    ) : (
                      <button className={cn("button", styles.button2)}>
                        添加
                      </button>
                    )
                  }

                </div>
            </Modal>
          </div>
        ) : current == 3 ? (
          <div>
            <div className={styles.topBox}>
              <div className={styles.tabBox}>
                <span className={styles.cardBox}></span>
                <span className={current == 1 ? styles.tite : styles.none} onClick={()=>{setCurrent(1)}}>商品信息</span>
                <span className={current == 2 ? styles.tite : styles.none} onClick={()=>{setCurrent(2)}}>自定义总部价格</span>
                <span className={current == 3 ? styles.tite : styles.none} onClick={()=>{setCurrent(3)}}>自定义门店价格</span>
              </div>
              <div className={styles.formbox}>
                <Form
                    className={styles.form}
                    value={search}
                    setValue={(e)=>{setSearch(e)}}
                    onSubmit={() =>{setSearchValue(search)}}
                    placeholder="搜索商户门店"
                    type="text"
                    name="search"
                    icon="search"
                />
                <button className={cn("button", styles.addbutton) } onClick={() => {initPrice()}}>
                  <Icon name="add" size="24" />
                  <span>自定义价格</span>
                </button>
              </div>
            </div>
            <PriceControl isHas={isHas} modl={'store'} value={searchValue} goodsId={dataSource.id} goodsNo={dataSource.goods_bar_no} goodsName={dataSource.goods_name}/>

            <Modal 
              outerClassName={styles.outer}
              visible={visiblePrice}
              onClose={() => setPriceModel(false)}
              isZzc={true}
            >
                <div className={styles.com_box}>
                  <SelectPrice onClose={() => setPriceModel(false)} modl={'store'} handleOutlet={(e)=>{setShowOutlet(e)}} pricsChangeEmit={(e) => pricsChangeEmit(e)} />

                  {
                    showOutlet ? (
                      <SelectOutlet modl={'store'} selsectChangeEmit={(e)=>{selsectChangeEmit(e)}}/>
                    ) : null
                  }

                  {
                    priceOptions > 0 && (selectOptions || []).length > 0? (
                      <button className={cn("button", styles.button3)} onClick={()=>addChangeReat()}>
                        添加
                      </button>
                    ) : (
                      <button className={cn("button", styles.button2)}>
                        添加
                      </button>
                    )
                  }

                </div>
            </Modal>
          </div>
        ) : null
      }
    </>
  );
};

export default NewProduct;
