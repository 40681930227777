import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import utils from "../../common";
import aes from '../../api/aes/public';
import { useHistory, useLocation } from "react-router";

const SignIn = () => {
  const heightWindow = use100vh();
  const query = useLocation()
  const router = useHistory()
  const [login_name,setLoginName] = useState('');
  const [login_pwd,setLoginPwd] = useState('');

  useEffect(()=>{
    getKey()
  },[])

  // 从其他渠道进入login，直接获取登录信息登录  
  // 🌰 http://localhost:8080/products/signIn?key=3kKyAk+GaGdcq/PPC8x/Jdd0hqe49Yp6Xd7XPZd3wlDtpb8tTUZJTFmTYxQtHBYgEP/Z9kpkWR40YeNWqx31FL0mnlIgRy7kTTnkbao+sOmPC7zVkRRaYWaY3xeAQaSrBDOKxM9Q5dSrZcgM5QWXnefkS/crI5DLQXfTR4/hVmNFsq3oS/oAasRVik2g3ZxGY7TAFKAj60ptHBs4zh4RiD8sSJtGOsMYogiDK6k/4J/YsXklJLrySK/o/YiDVSQtsrWwzvvFoq8sM4zhMxp5ow==

  const getKey = () => {
    if (query.search) {
      const key = utils.getQueryString(query.search).key;
      const res = JSON.parse(aes.Decrypt(key));
      loginCallback(res);
    }
  }
  //填写 账号密码
  const handleChange = (e,tag)=>{
    switch(tag){
      case 'name':setLoginName(e.target.value);break;
      case 'pwd':setLoginPwd(e.target.value);break;
    }
  }

  const loginIn = () => {
    window.api('eps.login',{
      login_name,
      login_pwd
    }).then(res=>{
      loginCallback(res)
    })
  }


  // 登陆后的处理
  const loginCallback = (res) => {
    const headParams = {login_name: res.login_name, partner_id: res.partner_id};
    window.localStorage.setItem('fullName', res.full_name);
    window.localStorage.setItem('level', res.stock_level_id);
    window.localStorage.setItem('PKEY', res.partner_key);
    window.localStorage.setItem('identity', res.identity); // 登录者身份 0：平台 1：代理商 2：商户
    window.localStorage.setItem('headParams', JSON.stringify(headParams));
    let url = ''
    if(res.identity == 0){
      url = '/products/goodsList'
    }else if(res.identity == 1){
      url = '/products/shop'
    }else if(res.identity == 2 || res.identity == 3){
      url = '/products/shopProduct'
    }else{
      url = '/products/signIn'
    }
    router.replace({
      pathname: url
    })
  }

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        {/* <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link> */}
        <div className={cn("h4", styles.title)}>{'联拓商城&服务市场'}</div>
        <div className={styles.head}>
          {/* <div className={styles.subtitle}>Sign up with Open account</div> */}
          {/* <div className={styles.btns}>
            <button className={cn("button-stroke", styles.button)}>
              <img src="/images/content/google.svg" alt="Google" />
              Google
            </button>
            <button className={cn("button-stroke", styles.button)}>
              <Image
                className={styles.pic}
                src="/images/content/apple-dark.svg"
                srcDark="/images/content/apple-light.svg"
                alt="Apple"
              />
              Apple ID
            </button>
          </div> */}
        </div>
        <div className={styles.body}>
          {/* <div className={styles.subtitle}>Or continue with email address</div> */}
          <div className={styles.box}>
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="用户名"
              required
              icon="profile-circle"
              value={login_name}
              onInput={(e)=>handleChange(e,'name')}
            />
          </div>
          <div className={styles.box}>
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="密码"
              required
              icon="lock"
              value={login_pwd}
              onInput={(e)=>handleChange(e,'pwd')}
            />
          </div>
          
          <button className={cn("button", styles.button)} style={{'fontSize': '18px'}} onClick={()=>loginIn()}>登 录</button>
          {/* <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </div>
          <div className={styles.info}>
            Don’t have an account?{" "}
            <Link className={styles.link} to="/sign-up">
              Sign up
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
