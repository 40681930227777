import React from "react";
import styles from "./Product.module.sass";
import cn from "classnames";

const Product = ({ item, className }) => {
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.preview}>
        <img srcSet={`${item.goods_pic} 2x`} src={item.goods_pic} alt="Product" />
      </div>
      <div className={styles.details}>
        <div className={styles.product}>{item.goods_name}</div>
        {
          item.status == 0 ? (
             <div className={styles.none}>待付款</div>
          ) : null
        }
        {
           item.status == 3 ? (
              <div className={styles.new}>已完成</div>
           ) : null
        }
        {
           item.status == 4 ? (
              <div className={styles.progress}>已取消</div>
           ) : null
        }
      </div>
    </div>
  );
};

export default Product;
