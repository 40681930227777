import React, { useEffect, useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import Product from "./Product";
import Parameter from "./Parameter";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Editor from "../../../../components/Editor";

// const suggestions = [
//   "Talk to customer to see if you can help.",
//   "If not, approve or decline the request.",
// ];


const Details = ({  itemDetail,item, onClose }) => {

  // const [content, setContent] = useState();
  const parameters = [
  {
    title: "订单编号",
    content: itemDetail.order_no || '-',
  },
  {
    title: "订单金额",
    price: item.total_amt || 0,
  },
  {
    title: "商品成本",
    price: itemDetail.goods_cost_price * itemDetail.goods_qty || 0,
  },
  {
    title: "代理商收益",
    price: item.order_extend?.agentEarning || 0,
  },
  {
    title: "联拓收益",
    price: item.order_extend?.platformEarning || 0,
  },
  {
    title: "下单时间",
    content: itemDetail.gmt_created || '',
  },
  {
    title: "购买数量",
    content: itemDetail.goods_qty
  },
  {
    title: "有效期",
    content: 365 * itemDetail.goods_qty + '天',
  },
  ];

  const shopInfo = [
    {
      title: "区域",
      content: "--",
    },
    {
      title: "商户名称",
      content: item.merchant_name,
    },
    {
      title: "商户编号",
      content: item.merchant_code,
    },
    {
      title: "商户版本",
      content: '--',
    },
    {
      title: "服务商名称",
      content: item.agent_name || '',
    },
    {
      title: "服务商编号",
      content: item.agent_no || '',
    },
    {
      title: "服务商员工名称",
      content: item.agent_salesman_name || '',
    },
    {
      title: "服务商员工编号",
      content: item.agent_salesman_no || '',
    },
    {
      title: "平台名称",
      content: item.platform_name || '',
    },
    {
      title: "平台编号",
      content: item.platform_no || '',
    },
  ];



  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
         
          <div className={styles.col}>
            <div className={cn("title-purple", styles.title)}>订单详情</div>
            <Product className={styles.product} item={itemDetail} />
            <div className={styles.parameters}>
              {parameters.map((x, index) => (
                <Parameter item={x} key={index} />
              ))}
            </div>
            {/* <div className={styles.btns}>
              <button className={cn("button-stroke", styles.button)}>
                Decline refund
              </button>
              <button className={cn("button", styles.button)}>
                Give refund
              </button>
            </div> */}
          </div>

          <div className={styles.col}>
            <div className={cn("title-purple", styles.title)}>商户/服务商信息</div>
            <div className={styles.parameters}>
              {shopInfo.map((x, index) => (
                <Parameter item={x} key={index} />
              ))}
            </div>
            <div className={styles.btns}>
              {/* <button className={cn("button-stroke", styles.button)}>
                订单退款
              </button> */}
              <button className={cn("button", styles.button)} onClick={onClose}>
                确定
              </button>
            </div>
          </div>
          {/* <div className={styles.col}>
            <div className={styles.group}>
              <div className={styles.box}>
                <div className={styles.info}>Suggestions</div>
                <ul className={styles.list}>
                  {suggestions.map((x, index) => (
                    <li key={index}>{x}</li>
                  ))}
                </ul>
              </div>
              <div className={styles.box}>
                <div className={styles.info}>Download link is broken</div>
                <div className={styles.text}>
                  “ I can’t download your item at all. Even tried to change the
                  DNS or VNP, it still doesn’t work.{" "}
                  <span role="img" aria-label="smile">
                    😢
                  </span>
                  ”{" "}
                </div>
                <div className={styles.user}>
                  <div className={styles.avatar}>
                    <img src={item.avatar} alt="Avatar" />
                  </div>
                  {item.man}
                </div>
              </div>
            </div>
            <Editor
              state={content}
              onChange={setContent}
              classEditor={styles.editor}
              label="Send message"
              tooltip="Send message"
              button="Send"
            />
          </div> */}
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Details;
