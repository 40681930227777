import React from "react";
import cn from "classnames";
import styles from "./PayHistory.module.sass";
import Card from "../../../components/Card";
import { numberWithCommas } from "../../../utils.js";
import utils from "../../../common";
import { useHistory } from "react-router";
import { Empty } from "antd";

const PayHistory = ({ className, list }) => {
  const router = useHistory()
  const goDetail = (time, num) => {
    router.push({
      pathname:"/products/EarningsList",
      state:{
        time:time,
        num:num
      }
    })
  }
  return (
    <Card
      className={cn(styles.card, className)}
      title="历史收益"
      classTitle="title-blue"
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>结算日期（0点~24点）</div>
            <div className={styles.col}>交易总额</div>
            <div className={styles.col}>交易笔数</div>
            <div className={styles.col}>退款金额</div>
            <div className={styles.col}>退款笔数</div>
            <div className={styles.col}>产生收益</div>
            <div className={styles.col}>查看详情</div>
          </div>
          {
            (list || []).length > 0 ? (
              (list || []).map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>{(utils.getDate(x.statDate, 3))}</div>
                  <div className={styles.col}>¥{x.tradeTotalAmount || 0}</div>
                  <div className={styles.col}>{x.tradeTotalCount || 0}</div>
                  <div className={styles.col}>¥{x.refundTotalAmount || 0}</div>
                  <div className={styles.col}>{x.refundTotalCount || 0}</div>
                  <div className={styles.col}>
                    <div className={cn("status-sy", styles.status)}>¥{x.earnings || 0}</div>
                  </div>
                  <div className={styles.col}>
                    <button
                      className={cn("button", styles.btnwh2)}
                      onClick={() => goDetail(x.statDate, 2)}
                    >
                      查看详情
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.empty}>
                <Empty />
              </div>
            )
          }
        </div>
      </div>
    </Card>
  );
};

export default PayHistory;
