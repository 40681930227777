import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../components/Icon";
import Actions from "../../../components/Actions";

const Panel = ({ identity, onConfirm, onCancel, otherData, type, status, handleClose, priceOnConfirm }) => {
  console.log(otherData)
  // const actions = [
  //   {
  //     title: "Preview",
  //     icon: "expand",
  //     action: () => setVisiblePreview(true),
  //   },
  //   {
  //     title: "Schedule product",
  //     icon: "calendar",
  //     action: () => setVisibleSchedule(true),
  //   },
  //   {
  //     title: "Get shareable link",
  //     icon: "link",
  //     action: () => {},
  //   },
  //   {
  //     title: "Clear data",
  //     icon: "close",
  //     action: () => {},
  //   },
  // ];

  return (
    <div className={cn("panel", styles.panel)}>
      {/* <div className={styles.info}>
        <Icon name="check-all" size="24" />
        Last saved <span>Oct 4, 2021 - 23:32</span>
      </div> */}
      <div className={styles.btns}>
        {
          type == 1 && (
            <>
              <button className={cn("button-stroke", styles.button)} onClick={() => onCancel()}>取消</button>
              <button className={cn("button", styles.button)} onClick={() => onConfirm()}>立即发布</button>  
            </>
          )
        }

         {/* 商品状态（0：出售中；1：已下架） */}

        {
          <>
            {
              identity != 1 ? (
                ( type == 2 && status == 0 ) && (
                  <>
                    <button className={cn("button-stroke", styles.button)} onClick={() => onCancel()}>下架</button>
                    <button className={cn("button", styles.button)} onClick={() => onConfirm()}>保存</button>  
                  </>
                )
              ) : (
                otherData.pay_type == 3 ? (
                  ( type == 2 && status == 0 && otherData.pay_type == 3 ) && ( // 3一口价，4面议
                    <>
                      <button className={cn("button", styles.button)} onClick={() => priceOnConfirm()}>保存修改</button>  
                    </>
                  )
                ) : (
                  ( type == 2 && status == 0 && otherData.pay_type != 3 ) && (
                    <>
                      <button className={cn("button", styles.button)} onClick={() => handleClose()} style={{'fontSize':'17px'}}>取  消</button>  
                    </>
                  )
                )
              )
            }
          </>
          
        }
       
        {
          ( type == 2 && status == 1 ) && (
            <>
              <button className={cn("button-stroke", styles.button)} onClick={() => onCancel()}>保存</button>
              <button className={cn("button", styles.button)} onClick={() => onConfirm()}>上架</button>  
            </>
          )
        }

        {
          ( type == 3 ) && (
            <>
              <button className={cn("button-stroke", styles.button)} onClick={() => handleClose()}>取消</button>
              <button className={cn("button", styles.button)} onClick={() => handleClose()}>确定</button>  
            </>
          )
        }
        
        {/* <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
          up
        /> */}
      </div>
    </div>
  );
};

export default Panel;
