
import React, { useEffect, useState } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { useLocation } from "react-router";

const  WyPage = ({}) => {
    const query = useLocation()
    const htmlContent = query.state ? query.state.htmlContent : '';
    const new_html = htmlContent.replace(/javascript:;/, "")
    const node_html = new_html.replace(/checked/, "defaultChecked={true}")
    const [dataSource, setDataSource] = useState({})
    useEffect(() => { 

    }, [ ])

    return (
        <>
            {ReactHtmlParser(node_html)}
        </>
    )
}
export default WyPage