import React, { useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import Product from "./Product";
import Parameter from "./Parameter";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Editor from "../../../../components/Editor";

// const suggestions = [
//   "Talk to customer to see if you can help.",
//   "If not, approve or decline the request.",
// ];

const Details = ({ parameters, itemDetail, orderDetail, onClose, isOK }) => {

  const productDetail = {
    goods_pic: orderDetail.goods_pic,
    goods_name: orderDetail.goods_name,
    status: orderDetail.status
  }
  
  const onConfirm = (num) =>{
    isOK(num)
  }

  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
         
          {
            parameters ?
            (
              <div className={styles.col}>
                <div className={cn("title-purple", styles.title)}>订单详情</div>
                <Product className={styles.product} item={productDetail} />
                <div className={styles.parameters}>
                  {/* {parameters.map((x, index) => (
                    <Parameter item={x} key={index} />
                  ))} */}
                  <Parameter item={orderDetail} detail={itemDetail}/>
                </div>
                <div className={styles.btns}>
                  <button className={cn("button-stroke", styles.button)} onClick={onClose}>
                    取消
                  </button>
                  <button className={cn("button", styles.button)} onClick={() => onConfirm('123')}>
                    确定
                  </button>
                </div>
              </div>
            ):null
          }
          {/* {
            shopInfo ? 
            (
              <>
                  <div className={styles.col}>
                    <div className={cn("title-purple", styles.title)}>订单详情</div>
                    <div className={styles.parameters}>
                      {shopInfo.map((x, index) => (
                        <Parameter item={x} key={index} />
                      ))}
                    </div>
                    <div className={styles.btns}>
                      <button className={cn("button-stroke", styles.button)}>
                        订单退款
                      </button>
                      <button className={cn("button", styles.button)}>
                        确定
                      </button>
                    </div>
                  </div>
                </>
              ) : null
          } */}

          
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Details;
