import axios from 'axios'
import { message } from 'antd';
// import { History, useHistory } from 'react-router'

// const router = useHistory()

const instance = axios.create({
    baseURL: '',
    // baseURL: 'http://eps.liantuobank.com/eps/base/',
    timeout: 50000,
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
  instance.interceptors.response.use(
    res => {
      const promise = new Promise((resolve, reject) => {
        if (res.status === 200 && res.data.body.service_status === 'S' && res.data.head.visit_status === 'S' || res.data.body.is_success === 'S') {
          resolve(res.data.body);
        } else {
          reject(res.data.body);
          if(res.status === 200 && res.data.head.visit_status === 'S' && res.data.body.is_success == 'F'){
            message.error(res.data.body.message)
          }else{
            let strMsg = res.data.body.service_error_message;
            let flag = strMsg.indexOf("信息失效")
            if(flag !== -1){
              window.location.replace("/products/signIn")
            }
            message.error(res.data.body.service_error_message)
          }
          
        }
      });
      return promise;
    },
    err => {
      
    }
  );
  export default instance;