import React from "react";
import styles from "./Parameter.module.sass";
import cn from "classnames";
import Tooltip from "../../../../../components/Tooltip";

const Parameter = ({ item, detail }) => {
const detailArr = [
  {
    title: "有效期",
    content: 365 * item.goods_qty + '天',
  },
  {
    title: "商户名称/编号",
    content: detail.merchant_name + '/' + detail.merchant_code,
  },
  {
    title: "销售名称/编号",
    content: detail.agent_salesman_name + '/' + detail.agent_salesman_no,
  },
  {
    title: "价格",
    price: detail.total_amt,
  },
  {
    title: "商品收益",
    price: detail.order_extend?.agentEarning || 0,
  },
  {
    title: "订单来源",
    content: "--",
  },
  {
    title: "下单时间",
    content: item.gmt_created,
  },
  {
    title: "订单编号",
    content: item.order_no,
  },
]

  return (
    <>
    {
      detailArr.map((x, index) => (
      <div className={styles.parameter}>
        <div className={styles.title}>
          {x.title}
        </div>
        
        {x.content && <div className={styles.content}>{x.content}</div>}
        
        {x.price && (
          <div className={styles.price}>¥{x.price.toFixed(2)}</div>
        )}
      </div>
    ))
    }
    </>
  );
};

export default Parameter;
