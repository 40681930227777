import React from "react";
import styles from "./Statements.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import Transactions from "./Transactions";
import { Button, message } from 'antd';


const OrderList = () => {

  const info = () => {
    message.info('This is a normal message');
  };
  
  return (
    <>
      <div className={styles.section}>
        {/* <Overview className={styles.card} /> */}
        <Transactions />
      </div>
      <TooltipGlodal />
      {/* <Button type="primary" onClick={info}>
        Display normal message
      </Button> */}
    </>
  );
};

export default OrderList;
