import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./PageList.module.sass";
import utils from '../../common';

const PageList = () => {

  useEffect(() => {
  }, [])
  

  return (
    <div className={styles.page} style={{ fontSize: 16, fontWeight: 500 }}>

      {/* 平台 */}
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/goodsList">平台-商品管理</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/add">新建，编辑商品</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/dashboard">平台-销售报表</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/orderList">平台-订单管理</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/categroyList">平台-分类管理</Link>
      </p>

      {/* 代理商 */}
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/shop">服务商-商品管理</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/fwsOrderList">服务商-订单管理</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/fwsDashboard">服务商-销售报表</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/PayEarnings">服务商-收益报表</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/EarningsList">收益详情（xx）</Link>
      </p>

      {/* 商户 */}
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/shopProduct">商户-商品管理</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/shopConfirm">商户-确认订单</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/shopOrderList">商户-我的订单</Link>
      </p>
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/payOrder">商户-订单支付</Link>
      </p>

      {/* 收银台 */}
      <p style={{ marginBottom: 5 }}>
        <Link to="/products/checkStand">收银台</Link>
      </p>

    </div>
  );
};

export default PageList;
