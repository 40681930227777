import React, { useEffect, useState, useSyncExternalStore } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import { WithContext as ReactTags } from "react-tag-input";
import Tooltip from "../../../components/Tooltip";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Tags from "../../../components/Tags";

const NameAndDescription = ({ identity, className, submitMsgInfo, defaultData }) => {
  //tangbin   产品详情接口没有产品规格、产品标签字段
  // 简介
  const blocksFromHtml = htmlToDraft(defaultData.goods_intro || '');
  const { contentBlocks, entityMap } = blocksFromHtml;
  const [goods_intro, setProductCut] = useState( EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)) );

  // 详情
  const blocksFromHtml2 = htmlToDraft(defaultData.goods_details || '');
  const { contentBlocks:contentBlocks2, entityMap:entityMap2 } = blocksFromHtml2;
  const [goods_details, setProductDetail] = useState( EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks2, entityMap2)) );

  const [goods_name,setProductName] = useState(defaultData.goods_name || '') // 产品名称

  const [skuTags, setSkuTags] = useState(defaultData?.skuTags || []); // sku

  const [categroyTags, setCategroyTags] = useState(defaultData?.categroyTags || [{ id: "1", text: "西安" },{ id: "1", text: "杭州" }]);  // tag

  useEffect(()=>{
    handleSubmit()
  },[goods_name])

  const isSameArr = (arr,brr)=>{
    if(arr?.length == brr.length){
      for(var i= 0;i<arr.length ; i++){
        if(JSON.stringify(arr[i]) !== JSON.stringify(brr[i])){
          return false;
        }
      }
    }else{return false}
    return true;
  }

  const handleChange =(e,tag)=> {
    switch(tag){
      case 'goods_name':
        setProductName(e.target.value)
        break;
      case 'goods_details':
        setProductDetail(e) // 实时显示详情
        break;
      case 'goods_intro':
        setProductCut(e) // 实时显示简介
        break;
      case 'sku':
        e.map(item=>item.value=item.value || 0)
        setSkuTags([...e])
        break;
      case 'tagTag':
        if(!isSameArr(e,categroyTags)){
          setCategroyTags([...e])
        }
        break
    }
    handleSubmit(e,tag)
  }

  //提交
  const handleSubmit = (e,tag)=>{
    submitMsgInfo({
      goods_name,
      // skuTags:tag == 'sku'?[...e]:[...skuTags],
      // categroyTags,
      // goods_intro:convertToRaw(goods_intro.getCurrentContent()).blocks[0].text,
      goods_intro: draftToHtml(convertToRaw(goods_intro.getCurrentContent())), // 提交简介数据
      // goods_details:convertToRaw(goods_details.getCurrentContent()).blocks[0].text,
      goods_details: draftToHtml(convertToRaw(goods_details.getCurrentContent())),  // 提交详情数据
    })
  }

  return (
    <Card>
      <div className={styles.description}>
        {
          identity != 1 ? (   
            <TextInput
              className={styles.field}
              label="产品名称"
              name="title"
              type="text"
              tooltip="最多20个字"
              required
              value={goods_name}
              onChange={(e)=>handleChange(e,'goods_name')}
            />
          ) : (
            <div className={styles.fieldset}>
              <TextInput
                className={styles.field}
                label="产品名称"
                name="title"
                type="text"
                tooltip="最多20个字"
                required
                value={goods_name}
                onChange={(e)=>handleChange(e,'goods_name')}
              />
              <div className={styles.zz_bg}></div>
            </div>
          )
        }
        
        {
          identity != 1 ? (
            <Editor
              stateInfo={goods_intro}
              onChange={(val)=>{handleChange(val,'goods_intro')}}
              classEditor={styles.editor}
              label="产品简介"
              tooltip="描述产品的特色"
            />
          ) : null
        }
        {
          identity != 1 ? (
            <Editor
              stateInfo={goods_details}
              onChange={(val)=>handleChange(val,'goods_details')}
              classEditor={styles.editor}
              label="产品详情"
              tooltip="描述产品的详情"
            />
          ) : null
        }
        {/* <div className={styles.group}>
          <TextInput
            className={styles.field}
            label="Key features"
            name="value1"
            type="text"
            placeholder="Value"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
          />
          <TextInput
            className={styles.field}
            name="value2"
            type="text"
            placeholder="Value"
            required
          />
          <TextInput
            className={styles.field}
            name="value3"
            type="text"
            placeholder="Value"
            required
          />
          <TextInput
            className={styles.field}
            name="value4"
            type="text"
            placeholder="Value"
            required
          />
        </div> */}
        {/* 产品规格 */}
        {/* <div className={styles.head}>
          <div className={styles.label}>
            产品规格
            <Tooltip
              className={styles.tooltip}
              title="最大添加10个"
              icon="info"
              place="right"
            />
          </div>
          <div className={styles.counter}>
            <span>1</span>/10 分类
          </div>
        </div>
        <div className={styles.tags}>
          <Tags tagList={skuTags} handleEmit={handleChange} tagFlag='sku'/>
        </div> */}

        {/* 产品标签 */}
        {/* <div className={styles.head}>
          <div className={styles.label}>
            产品标签
            <Tooltip
              className={styles.tooltip}
              title="最大添加11个"
              icon="info"
              place="right"
            />
          </div>
          <div className={styles.counter}>
            <span>1</span>/11 标签
          </div>
        </div>
        <div className={styles.tags}>
          <Tags tagList={categroyTags} handleEmit={handleChange} tagFlag='tagTag'/>
        </div> */}
      </div>
    </Card>
  );
};

export default NameAndDescription;
