import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import styles from "./Released.module.sass";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Icon from "../../components/Icon";
import Market from "./Market";
import Product from "../../components/Product";
import Loader from "../../components/Loader";
import Panel from "./Panel";
/** 商品管理 */
// data
import { products } from "../../mocks/products";
import utils from "../../common";
import DropdownId from "../../components/DropdownId";

// const sorting = ["list", "grid"];

const Released = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [dataSource,setDataSource] = useState([]);
  const [categoryList,setCategory] = useState([]);
  const [cate, setCate] = useState( {} );
  const router = useHistory()

  useEffect(()=>{
    api_getProdCategory()
  },[])

  useEffect(()=>{
    getGoodsList()
  },[cate])

  useEffect(()=>{
    
  },[dataSource])

  const productDetail = (type) =>{
    router.push({
      pathname:"/products/add",
      state:{
        type:type,
      }
    })
  };

  const getGoodsList = ()=> {
    let params = {
      page_size: 500,
      current_page: 1,
      status:'', // 商品状态（0：出售中；1：已下架）
      service_type:1, // 服务类型 0 老商户 1 服务市场
      goods_name: search || '',
      goods_bar_no: '',
      goods_category_id: cate?.id || '',
      // goods_category_name: e?.value || '',
      goods_property: 0, // 商品类型0 全部  1软件 2硬件 3 流量
    }
    params = utils.dealElement(params);
    window.api('goods.getgoodslist', params).then(res => {
      if(res.service_status == 'S'){
        setDataSource(res.goods_list)
      }
    }).catch((error) => {
      setDataSource([])
    });
  }

  const setTabBar = () => {
    alert('999')
  };

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  // 获取商品分类
  const api_getProdCategory = ()=>{
    window.api('goods.getcategorylist', {}).then(res => {
      if(res.success){
        const optionsCate = [{value:'全部分类',id:''}];
        let cate_arr = res.goods_category_list.filter((item)=>item.goods_category_hierarchy == 1 && item.service_type == 1)
        cate_arr.map(item=>{
          optionsCate.push({value:item.goods_category_name, id:item.id})
        });
        setCategory([ ...optionsCate ])
      }
    }).catch(() => {
      setCategory([])
    })
  }

  const setCateHandle = (e) =>{
    setCate(e)
  }

  return (
    <>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="商品管理"
        classTitle={cn("title-purple", styles.title)}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={(e)=>{setSearch(e)}}
              onSubmit={() => getGoodsList()}
              placeholder="搜索商品名称/编号"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.dropdown}>
              <DropdownId
                selectVal={cate?.id ? cate.value : '全部分类'}
                setValue={(e)=>{setCateHandle({...e})}}
                options={categoryList}
                small
              />
            </div>
            {/* <div className={styles.sorting}>
              {sorting.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  <Icon name={x} size="24" />
                </button>
              ))}
            </div> */}
            <div className={styles.btns}>
              <button className={cn("button", styles.addbutton) } onClick={() => {productDetail(1)}}>
                <Icon name="add" size="24" />
                <span>添加商品</span>
              </button>
              {/* <button className={cn("button-stroke-red", styles.button)}>
                <span>Deleted</span>
                <Icon name="trash" size="24" />
              </button> */}
              {/* <button className={cn( styles.button, styles.btnCol)} onClick={() => setTabBar()}>面向商户</button> */}
            </div>
          </>
        }
      >
        <div className={styles.wrapper}>
          {activeIndex === 0 && <Market goodsList={dataSource}/>}
          {/* {activeIndex === 1 && (
            <>
              <div className={styles.list}>
                {products.map((x, index) => (
                  <Product
                    className={styles.product}
                    value={selectedFilters.includes(x.id)}
                    onChange={() => handleChange(x.id)}
                    item={x}
                    key={index}
                  />
                ))}
              </div>
              <div className={styles.foot}>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                >
                  <Loader className={styles.loader} />
                  <span>Load more</span>
                </button>
              </div>
            </>
          )} */}
        </div>
      </Card>
      {/* <Panel /> */}
    </>
  );
};

export default Released;
