import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Icon from "../../../../components/Icon";
import Balance from "../../../../components/Balance";
import ModalProduct from "../../../../components/ModalProduct";
import { numberWithCommas } from "../../../../utils.js";
import { useHistory } from "react-router";

const Row = ({ item, value, onChange }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const router = useHistory()

  //编辑商品

  const productDetail = (type) =>{
    router.push({
      pathname:"/products/add",
      state:{
        type: type,
        id: item.id
      }
    })
  };

  return (
    <>
      <div className={styles.row}>
        {/* <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div> */}
        <div className={styles.col}>
          {/* 名称 */}
          <div
            className={styles.item}
            // onClick={() => {setVisibleModalProduct(true)}
          >
            <div className={styles.preview}>
              <img
                srcSet={`${item.goods_picture} 2x`}
                src={item.goods_picture}
                alt="商品图片"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product2}>{item.goods_name}</div>
              <div className={styles.wrap}>
                {/* <div className={styles.price}>${item.price}</div> */}
                <div className={styles.category}>产品编号：{item.goods_bar_no}</div>
              </div>
            </div>
          </div>
        </div>
        {/* 分类 */}
        <div className={styles.col}>{item.goods_category_name}</div>
        {/* 状态 */}
        <div className={styles.col}>
          {/* <div className={styles.label}>啊实打实的</div> */}
          {item.status ? (
            <div className={cn("status-down", styles.status)}>下架</div>
          ) : (
            <div className={cn("status-up", styles.status)}>上架</div>
          )}
        </div>
        {/* 底价 */}
        <div className={styles.col}>¥{item.cost_price}</div>
        {/* <div className={styles.col}>
          <div className={styles.label}>Rating</div>
          {item.ratingValue ? (
            <div className={styles.rating}>
              <Icon name="star-fill" size="24" />
              {item.ratingValue}{" "}
              <span className={styles.counter}>({item.ratingCounter})</span>
            </div>
          ) : (
            <div className={cn(styles.rating, styles.ratingEmpty)}>
              <Icon name="star-stroke" size="24" />
              No ratings
            </div>
          )}
        </div> */}
        {/* 默认价格 */}
        <div className={styles.col}>
          {/* <div className={styles.label}>是对的时代</div> */}
          <div className={styles.sales}>
            <div className={styles.number}>¥{item.discount_price}</div>
            {/* <Balance className={styles.balance} value={item.balance} /> */}
          </div>
        </div>
        {/* 商品管理 */}
        <div className={styles.col}>
          <div className={styles.label}>Views</div>
          <div className={styles.box}>
            {/* <div className={styles.number}>
              {(item.views / 1000).toFixed(0)}k
            </div>
            <div className={styles.line}>
              <div
                className={styles.progress}
                style={{
                  backgroundColor: "#2A85FF",
                  width: item.viewsPercent,
                }}
              ></div>
            </div> */}
            <button className={cn('button', styles.btnwh)} onClick={() => productDetail(2)}>编辑商品</button>
          </div>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
