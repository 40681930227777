import axios from 'axios'
import qs from 'qs'

// const env = process.env.NODE_ENV

// let BASE_URL = {
//     production: {
//         open: 'https://houtai.liantuofu.com'
//     },
//     development: {
//         open: 'https://testapi.liantuofu.com'
//     },
//     none: {
//         open: 'https://testapi.liantuofu.com'
//     }
// }

let BASE_URL = ''

if (window.location.hostname === 'localhost' || window.location.hostname === 'preeps.51ebill.com') { // 本地/测试
    BASE_URL = 'https://testapi.51ebill.com'
  } else if (window.location.hostname === 'intshop.51ebill.com'){ // 灰度
    BASE_URL = 'https://intshop.51ebill.com'
  } else { // 生产
    BASE_URL = 'https://houtai.liantuofu.com'
  }

const openBaseUrl = BASE_URL
const openService = axios.create({
    withCredentials: true,
    baseURL: openBaseUrl
    // headers: { 'Access-Control-Allow-Origin': '*' }
})
openService.interceptors.request.use(
    config => config,
    error => Promise.reject(error)
)
  
openService.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
)

export default {
    openPost(url, data) {
        return openService({
        method: 'post',
        url,
        data: qs.stringify(data)
        // headers: {
        //   'Content-type': 'application/json'
        // }
        })
    },
}