import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import Balance from "../../../components/Balance";
import Chart from "./Chart";
import { DatePicker, Space, ConfigProvider } from 'antd';
import { numberWithCommas, getTimestamp } from "../../../utils.js";
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

const intervals = ["今日", "昨日", "自定义时间"];



const Overview = ({ totalData, className, stateTypeChange, timeDateChange  }) => {
  const { RangePicker } = DatePicker;

  const [sorting, setSorting] = useState(intervals[0]);
  const [isTime, setIsTime] = useState(false);

  const items = [
    {
      title: "销售金额",
      counter: totalData.salesAmt,
      icon: "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/sale_amount.png",
      color: "#B5E4CA",
      tooltip: "销售金额",
      value: 37.8,
    },
    {
      title: "销售数量",
      counter: totalData.salesNum,
      icon: "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/sale_num.png",
      color: "#CABDFF",
      tooltip: "销售数量",
      value: -17.8,
    },
    {
      title: "平台收益",
      counter: totalData.platIncome,
      icon: "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/sale_pt.png",
      color: "#FFBC99",
      tooltip: "平台收益",
      value: 24.3,
    },
    {
      title: "服务商收益",
      counter: totalData.agentIncome,
      icon: "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/sale_fws.png",
      color: "#B1E5FC",
      tooltip: "服务商收益",
      value: -11.3,
    },
  ];


  const setSortingChange = (x, idx)=> {
    if(x != '自定义时间'){
      setIsTime(false)
    }else{
      setIsTime(true)
    }
    setSorting(x)
    // getDataList()
  }
  
  useEffect(()=>{
    // getDataList()
    if(!isTime){
      timeDateChange([])
      stateTypeChange(sorting)
    }
  },[sorting])

  // 自定义时间
  const onChangeTimes = (value, dateString) => {
    timeDateChange(dateString)
  };

  const onOkTimes = (value) => {
    
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="销售报表"
        classTitle="title-red"
        head={
          <>
            <div className={styles.times}>
              {
                isTime ? (
                  
                    <Space direction="vertical" size={12}>
                      <ConfigProvider locale={locale}>
                        {/* <RangePicker
                          showTime={{
                            format: 'HH:mm',
                          }}
                          placeholder={['选择起始日期时间','选择截止日期时间']}
                          format="YYYY-MM-DD HH:mm"
                          onChange={onChangeTimes}
                          onOk={onOkTimes}
                        /> */}
                        <RangePicker onChange={onChangeTimes}/>
                      </ConfigProvider>
                    </Space>
                ) : null
              }
            </div>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={(x,idx) => setSortingChange(x,idx)}
              options={intervals}
              small
            />
          </>
        }
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  {/* <Icon name={x.icon} size="24" /> */}
                  {
                    ( index === 0 || index === 3 ) && <img className={styles.iconpic} src={x.icon} alt="图标" ></img>
                  }
                  {
                    index === 1 && <img className={styles.iconpic1} src={x.icon} alt="图标" ></img>
                  }
                  {
                    index === 2 && <img className={styles.iconpic2} src={x.icon} alt="图标" ></img>
                  }
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    {x.title}
                    <Tooltip
                      className={styles.tooltip}
                      title={x.tooltip}
                      icon="info"
                      place="right"
                    />
                  </div>
                  <div className={styles.counter}>{x.counter}</div>
                  <div className={styles.indicator}>
                    {/* <Balance className={styles.balance} value={x.value} /> */}
                    <span>{sorting}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
