import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import TextInput from "../../../../components/TextInput";
import Checkbox from "../../../../components/Checkbox";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Tooltip from "../../../../components/Tooltip";
import { PlusOutlined } from "@ant-design/icons";
import { Input, Space, Tag, theme, message } from "antd";

const Details = ({ tagItem, onCloseTag, tagList, getCateList }) => {
  const compatibility = [];

  // const [selectedFilters, setSelectedFilters] = useState([1, 2, 5, 6]);
  // const [smallValue, setSmallValue] = useState("");

  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [title, setTitle] = useState(tagItem.tagName)
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const tagInputStyle = {
    width: 78,
    verticalAlign: "top",
  };
  const tagPlusStyle = {
    background: "",
    borderStyle: "dashed",
    fontSize: "14px",
    borderRadius: "10px",
    padding: "4px 10px",
    fontWeight: 500,
    lineHeight: "25px",
  };

  useEffect(()=>{
    const cateList = tagList.filter((i)=> i.superior_id == tagItem.id && i.goods_category_hierarchy == 2 && i.service_type == 1)
    let arr = []
    cateList.forEach((item)=>{
      arr.push(item.goods_category_name)
    })
    setTags(arr)
  },[])

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  // 删除子分类
  const handleClose = (e) => {
    // 删除子分类
    api_delProdCategory(false, e)
    const newTags = tags
    setTags(newTags);
  };

  // 添加子分类
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
      // 添加子分类
      api_addProdCategory(inputValue)
    }
    setInputVisible(false);
    setInputValue("");
  };

  // 修改子分类
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    if(!editInputValue){
      message.warning('请输入子分类名称');
      return false
    }
    api_editProdCategory(true, editInputIndex, editInputValue)
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };
  // 一级分类名称修改
  const handleChange = (e, tag) => {
    setTitle(e.target.value)
  };

  // 确认
  const confirmTag = (e, tag) => {
    if(!title){
      message.warning('请输入分类名称');
      return false
    }
    api_editProdCategory()
  };

  // 取消
  const cancelTag = (e, tag) => {
    onCloseTag()
  };

  // const checkChange = (id) => {
  //   let arr = [];
  //   if (selectedFilters.includes(id)) {
  //     arr = selectedFilters.filter((x) => x !== id);
  //   } else {
  //     arr = [...selectedFilters, id];
  //   }
  //   setSelectedFilters(arr);
  // };

  // const addSmallCate = () => {
  // };

  // 修改
  const api_editProdCategory = (flag, i, val) => {
    let params = {}
    if(flag){ // 修改子分类
      let title = tags[i]
      let idx = tagList.findIndex((e)=> e.goods_category_name == title)
      params = {
          goods_category_name: val,
          superior_id: tagList[idx].superior_id,
          id: tagList[idx].id
      }
    }else{ // 修改一级分类标题
      params = {
          goods_category_name: title,
          superior_id: tagItem.superior_id,
          id: tagItem.id,
      }
    }
    window.api('goods.modcategory', params).then(res => {
        if(res.service_status == 'S'){
          flag ? getCateList() : onCloseTag(true)
        }
      }).catch(() => {
        
      })
  };
  // 增加
  const api_addProdCategory = (val) => {
    let params = {
        goods_category_name: val,
        superior_id: tagItem.id,
        goods_category_hierarchy: 2,
    }
    if(tagItem.tag == 'systemTag'){
        params.service_type = 1
    }
    window.api('goods.addcategory', params).then(res => {
        if(res.service_status == 'S'){
          getCateList() 
        }
      }).catch(() => {
        
      })
  };
  // 删除
  const api_delProdCategory = (flag, val) => {
    let params = {}
    if(flag){ // 删除一级分类
      params = {
        id: val,
        service_type: 1
      }
    }else{ // 删除子分类
      let idx = tagList.findIndex((e)=> e.goods_category_name == val)
      params = {
        id: tagList[idx].id,
        service_type: 1
      }
    }
    window.api('goods.delcategory', params).then(res => {
      if(res.service_status == 'S'){
        flag ? onCloseTag(true) : getCateList() 
      }
    }).catch(() => {
      onCloseTag()
    })
  };


  return (
    <>
      {tagItem.idx >= 0 ? (
        <>
          <div className={cn("title-purple", styles.title)}>
            {tagItem.tag == "systemTag"
              ? "服务市场商品分类"
              : "联拓商城商品分类"}
          </div>
          <div className={styles.titleName}>
            <div className={styles.titleinput}>
              <TextInput
                className={styles.field}
                label="分类名称"
                name="title"
                type="text"
                tooltip="一级分类"
                required
                value={title}
                onChange={(e) => handleChange(e, tagItem.tag)}
              />
            </div>
            <button className={cn("button", styles.buttondel)} onClick={()=>api_delProdCategory(true, tagItem.id)}>
              删除分类
            </button>
          </div>
          <div className={styles.label}>
            {title} - 子分类
            <Tooltip
              className={styles.tooltip}
              title="二级分类"
              icon="info"
              place="right"
            />
          </div>
          {/* <div className={styles.list}>
            {compatibility.map((x, index) => (
              <Checkbox
                className={styles.checkbox}
                content={x.title}
                value={selectedFilters.includes(x.id)}
                onChange={() => checkChange(x.id)}
                key={index}
              />
            ))}
          </div> */}
          {/* <div className={styles.addfelx}>
            <TextInput
              className={styles.input}
              type="text"
              value={smallValue}
              onChange={(e) => setSmallValue(e.target.value)}
              placeholder='输入添加子分类'
              required
            />
            <button className={cn('button', styles.btnwh)} onClick={addSmallCate}>添加</button>
          </div> */}
          <div>
            <Space size={[0, 10]} wrap>
              <Space size={[0, 10]} wrap>
                {tags.map((tag, index) => {
                  if (editInputIndex === index) {
                    return (
                      <Input
                        ref={editInputRef}
                        key={tag}
                        size="default"
                        style={tagInputStyle}
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                      />
                    );
                  }
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      size="default"
                      key={tag}
                      closable={index !== -1}
                      style={{
                        userSelect: "none",
                        fontSize: "14px",
                        borderRadius: "10px",
                        padding: "4px 10px",
                        fontWeight: 500,
                        lineHeight: "25px",
                      }}
                      onClose={() => handleClose(tag)}
                    >
                      <span
                        onDoubleClick={(e) => {
                          if (index !== -1) {
                            setEditInputIndex(index);
                            setEditInputValue(tag);
                            e.preventDefault();
                          }
                        }}
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </span>
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}
              </Space>
              {inputVisible ? (
                <Input
                  ref={inputRef}
                  type="text"
                  size="default"
                  style={tagInputStyle}
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              ) : (
                <Tag style={tagPlusStyle} onClick={showInput} size="default">
                  <PlusOutlined /> 添加子分类
                </Tag>
              )}
            </Space>
          </div>
          <div className={styles.btns}>
              <button className={cn("button", styles.cancelbutton)} onClick={()=>cancelTag()}>
                取消
              </button>
              <button className={cn("button", styles.button)} onClick={()=>confirmTag()}>
                确定
              </button>
          </div>
        </>
      ) : null}

      <TooltipGlodal />
    </>
  );
};

export default Details;
