import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from './api/instance';
import api from './api/api.js';
import api2 from './api/api2.js';
import common from './common';
import 'antd/dist/antd.css';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN'

window.axios = axios;
window.common = common;
window.api = api.baseInstance;
window.api2 = api2.baseInstance;

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}> 
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
