import React, { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
//文字筛选
const DropdownId = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  selectVal,//{value:'',id:''}
  setValue,//setValue({value:'',id:''})
  options,//[{value:'',id:''},{value:'',id:''}]
  title,
  tooltip,
  small,
  upBody,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (item, index) => {
    setValue(item, index);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {title && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {title}{" "}
          {tooltip ? (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          ):null}
        </div>
      )}
      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.selection}>{selectVal?.value || selectVal}</div>
        </div>
        <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
          {options.map((item, index) => (
            <div
              className={cn(styles.option, {
                [styles.selectioned]: item.value === selectVal.value,
              })}
              onClick={() => handleClick(item, index)}
              key={index}
            >
              {item.value}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default DropdownId;
