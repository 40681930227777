import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./CategoryAndAttibutes.module.sass";
import Card from "../../../components/Card";
import DropdownId from "../../../components/DropdownId";
import Tooltip from "../../../components/Tooltip";
import Checkbox from "../../../components/Checkbox";
import { WithContext as ReactTags } from "react-tag-input";
import useItems from "antd/lib/menu/hooks/useItems";

// const compatibility = [
//   {
//     id: 1101,
//     title: "1111",
//   },
//   {
//     id: 1102,
//     title: "2222",
//   },
//   {
//     id: 1103,
//     title: "3333",
//   },
//   {
//     id: 1104,
//     title: "4444",
//   },
//   {
//     id: 1105,
//     title: "5555",
//   },
//   {
//     id: 1106,
//     title: "6666",
//   },
//   {
//     id: 1107,
//     title: "7777",
//   },
//   {
//     id: 1108,
//     title: "8888",
//   },
//   {
//     id: 1109,
//     title: "9999",
//   },
//   {
//     id: 1111,
//     title: "0000",
//   }
// ];

const CategoryAndAttibutes = ({ identity, className, categoryEmit, defaultData ,categoryList, BrandList}) => {
  const optionsCategory = [], compatibility = [];
  categoryList.map(item=>{
    if(item.goods_category_hierarchy == 1){
      optionsCategory.push({value:item.goods_category_name, id:item.id, superid:item.superior_id, type:item.goods_category_hierarchy})
    }else if(item.goods_category_hierarchy == 2){
      compatibility.push({value:item.goods_category_name, id:item.id, superid:item.superior_id, type:item.goods_category_hierarchy})
    }
  });

  const optionsBrand = [];
  BrandList.map(item=>{
    optionsBrand.push({value:item.brand_name, id:item.id})
  });

  const [category, setCategory] = useState({});
  const [brand, setBrand] = useState( {} );
  const [smallType, setSmallType] = useState( {});
  const [smallList, setSmallList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(defaultData?.selectedFilters || []);

  const setCategoryHandle = (e) =>{
    setCategory(e)
  }

  const setBrandHandle = (e) =>{
    setBrand(e)
  }

  const smallTypeChange = (e) =>{
    setSmallType(e)
  }

  useEffect(()=>{
    const defaultCategory =  optionsCategory.filter((x) => {
      return  x.id == (defaultData.super_category_id ? defaultData.super_category_id : defaultData.goods_category_id)
    })
    setCategory({value:defaultCategory[0]?.value, id:defaultCategory[0]?.id, superid:defaultCategory[0]?.superid, type:defaultCategory[0]?.type })

    const defaultBrand =  optionsBrand.filter((x) => {
      return x.id == defaultData.goods_brand_id
    })
    setBrand({value:defaultBrand[0]?.value, id:defaultBrand[0]?.id})

    const defaultSmallType =  compatibility.filter((x) => {
      return x.id == defaultData.goods_category_id
    })
    setSmallType({value:defaultSmallType[0]?.value, id:defaultSmallType[0]?.id, superid:defaultSmallType[0]?.superid, type:defaultSmallType[0]?.type })

  },[])

  useEffect(()=>{
    handleChange()
  },[category, brand, smallType,smallList])

  useEffect(()=>{
    let arr = []
    arr = compatibility.filter((x) => x.superid == category.id)
    setSmallList(category.id > 0 ? [...arr] : [...compatibility])
  },[category])

  const handleChange = (id) => {
    let arr = []
    if (selectedFilters.includes(id)) {
      arr = selectedFilters.filter((x) => x !== id)
    } else {
      arr = [...selectedFilters, id]
    }
    setSelectedFilters(arr)
    categoryEmit({selectedFilters:arr, category:category , brand:brand, smallType: smallList?.length > 0 ? smallType : {}})
  };

  return (
    <>
      {
        identity != 1 ? (
          <Card
            className={cn(styles.card, className)}
            title="商品分类"
            classTitle="title-purple"
          >
            <div className={styles.images}>
              <DropdownId
                className={styles.field}
                title="商品品牌"
                selectVal={brand?.id ? brand.value : '点击选择'}
                setValue={(e)=>{setBrandHandle({...e})}}
                options={optionsBrand}
                tooltip="商品品牌"
              />

              <DropdownId
                className={styles.field}
                title="一级分类"
                selectVal={category?.id ? category.value : '点击选择'}
                setValue={(e)=>{setCategoryHandle({...e})}}
                options={optionsCategory}
                tooltip="一级分类"
              />
              
              {
                smallList?.length > 0 ?
                (
                  <DropdownId
                    className={styles.field}
                    title="二级分类"
                    selectVal={smallType?.id ? smallType.value : '点击选择'}
                    setValue={(e)=>{smallTypeChange({...e})}}
                    options={smallList}
                    tooltip="二级分类"
                  />
                ): ( null )
              }
              
              {/* <div className={styles.label}>
                
                <Tooltip
                  className={styles.tooltip}
                  title="二级分类"
                  icon="info"
                  place="right"
                />
              </div>

              <div className={styles.list}>
                {compatibility.map((x, index) => (
                  <Checkbox
                    className={styles.checkbox}
                    content={x.title}
                    value={selectedFilters.includes(x.id)}
                    onChange={() => handleChange(x.id)}
                    key={index}
                  />
                ))}
              </div> */}
              {/* <div className={styles.head}>
                <div className={styles.label}>
                  添加分类
                  <Tooltip
                    className={styles.tooltip}
                    title="最大添加20个"
                    icon="info"
                    place="right"
                  />
                </div>
                <div className={styles.counter}>
                  <span>1</span>/20 tags
                </div>
              </div>
              <div className={styles.tags}>
                <ReactTags
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  delimiters={delimiters}
                  handleTagClick={handleTagClick}
                  onClearAll={onClearAll}
                  onTagUpdate={onTagUpdate}
                  suggestions={categroyTags}
                  placeholder="输入回车添加分类"
                  minQueryLength={10}
                  maxLength={20}
                  autofocus={false}
                  allowDeleteFromEmptyInput={true}
                  autocomplete={true}
                  readOnly={false}
                  allowUnique={true}
                  allowDragDrop={true}
                  inline={true}
                  inputFieldPosition="inline"
                  allowAdditionFromPaste={true}
                  editable={true}
                  clearAll={true}
                  tags={tags}
                />
              </div> */}
            </div>
          </Card>
        ) : null
      }
    </>
    
  );
};

export default CategoryAndAttibutes;
