import React, { useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import Success from "./Success";
import { useHistory } from "react-router";

const Overview = ({ className, toDayDailySheet }) => {
  // const [visibleModal, setVisibleModal] = useState(false);
  const router = useHistory()
  
  const items = [
    {
      title: "今日收益",
      counter: "¥" + (toDayDailySheet.earnings || 0),
      icon: "activity",
      color: "#B5E4CA",
      tooltip: "今日收益",
    },
    {
      title: "订单笔数",
      counter: (toDayDailySheet.tradeTotalCount || 0) + "笔",
      icon: "pie-chart",
      color: "#CABDFF",
      tooltip: "订单笔数",
    },
  ];

  const goDetail = (time, num) => {
    router.push({
      pathname:"/products/EarningsList",
      state:{
        time:time,
        num:num
      }
    })
  }

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="今日收益"
        classTitle="title-blue"
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <Icon name={x.icon} size="24" />
                </div>
                <div className={styles.details}>
                  <div className={styles.label}>
                    {x.title}
                    <Tooltip
                      className={styles.tooltip}
                      title={x.tooltip}
                      icon="info"
                      place="right"
                    />
                  </div>
                  <div className={styles.counter}>{x.counter}</div>
                </div>
              </div>
            ))}
          </div>
          <button
            className={cn("button", styles.button)}
            onClick={() => goDetail(toDayDailySheet.statDate, 1)}
          >
            查看详情
          </button>
        </div>
      </Card>
      <TooltipGlodal />
      {/* <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Success />
      </Modal> */}
    </>
  );
};

export default Overview;
