import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ProductFiles.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";
import Control from "./Control";
import Modal from "../../../components/Modal";
import Details from "./Details";
import { message } from "antd";

const imageStyle = {
  display: 'inline-block',
  marginBottom: '15px',
  width: '100%',
  height: 'auto'
}

const ProductFiles = ({ identity, className , onChange, defaultData }) => {
  let {goods_intro_pics,goods_detail_pics } = defaultData;
  const [visibleModal, setVisibleModal] = useState(false);
  const [cutPic, setCutPic] = useState(goods_intro_pics?.split(',') || []);
  const [detailPic, setDetailPic] = useState(goods_detail_pics?.split(',') || []);
  const [itemImg, setiIemImg] = useState('');
  //上传
  const onChangePic = (e, type)=> {
    window.api('eps.upload', {
      goods_pic:e,
      service_type:1,
      pic_name: Math.ceil(Math.random()*1000000)+".png"
    }).then(res => {
      if(res.success){
        onChange(res.pic_path, type)
        if(type == 'cut'){
          let _cutPic = cutPic;
          _cutPic.push(res.pic_path);
          setCutPic([..._cutPic]);
          onChange([..._cutPic], type)
        }else{
          let _detailPic = detailPic;
          _detailPic.push(res.pic_path);
          setDetailPic([..._detailPic]);
          onChange([..._detailPic], type)
        }
      }else{
        message.error('上传失败')
      }
    })
  }

  const lookChange = (item, index, type) => {
    setVisibleModal(true)
    setiIemImg(item)
  }

  const deleteChange = (index, type) => {
    if(type == 'cut'){
      let picArr = cutPic
      picArr.splice(index, 1)
      setCutPic([...picArr])
      onChange([...picArr], 'cut')
    }else{
      let picArr2 = detailPic
      picArr2.splice(index, 1)
      setDetailPic([...picArr2])
      onChange([...picArr2], 'detail')
    }
  }

  return (
    
    <>
      {
        identity != 1 ? (
          <Card
          className={cn(styles.card, className)}
          title="上传图片"
          classTitle="title-blue"
        >
          <div className={styles.files}>
            <File
              style={imageStyle}
              className={styles.field}
              fileTag='cut'
              title="点击或拖动图片至此(图片不超过2M）"
              label="产品简介图"
              tooltip="每张图片最大不超过2M"
              onChange={(e)=>onChangePic(e,'cut')}
            />

          
            {
              ( cutPic || [] ).map((item,index) => {
              return <div className={styles.imgBox} key={index} style={{marginRight:'20px'}}>
                  <div className={styles.preview} >
                    <Control className={styles.control} lookChange={ () => lookChange(item, index, 'cut') } deleteChange={ () => deleteChange(index,'cut') } />
                    <img srcSet={`${item} 2x`} src={item} alt="" />
                  </div>
                </div>
  })
            }
            

            <File
              style={imageStyle}
              className={styles.field}
              fileTag='detail'
              title="点击或拖动图片至此(图片不超过2M)"
              label="产品详情图"
              tooltip="每张图片最大不超过2M"
              onChange={(e)=>onChangePic(e,'detail')}
            />

          
            {
              ( detailPic || [] ).map((item,index) => (
                <div className={styles.imgBox} key={index} style={{marginRight:'20px'}}>
                  <div className={styles.preview} >
                    <Control className={styles.control} lookChange={ () => lookChange(item, index, 'detail') } deleteChange={ () => deleteChange(index,'detail')} />
                    <img srcSet={`${item} 2x`} src={item} alt="" />
                  </div>
                </div>
              ))
            }
            

          </div>
        </Card>
        ) : null
      }
      
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details  onClose={() => setVisibleModal(false)} itemImg={itemImg} />
      </Modal>
    </>
  );
};

export default ProductFiles;
