import React, { useState } from "react";
import styles from "./Market.module.sass";
import cn from "classnames";
import Checkbox from "../../../components/Checkbox";
import Loader from "../../../components/Loader";
import Row from "./Row";
import Icon from "../../../components/Icon";
import { Empty } from 'antd';

// data
import { released } from "../../../mocks/market";

const Market = ( { goodsList }) => {
  const [chooseAll, setСhooseAll] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          {/* <div className={styles.col}>
            <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            />
          </div> */}
          <div className={styles.col}>商品名称</div>
          <div className={styles.col}>商品分类</div>
          <div className={styles.col}>商品状态</div>
          <div className={styles.col}>底价</div>
          <div className={styles.col}>默认售价</div>
          <div className={styles.col}>商品管理</div>
        </div>
        {
          (goodsList || []).length > 0 ? 
          (
            goodsList.map((x, index) => (
                <Row
                  item={x}
                  key={index}
                  value={selectedFilters.includes(x.id)}
                  onChange={() => handleChange(x.id)}
                />
              )
            )
          ): (
            <div className={styles.empty}>
              <Empty />
            </div>
          )
        }
      </div>
      {/* <div className={styles.foot}>
        <button className={cn("button-stroke button-small", styles.button)}>
          <Loader className={styles.loader} />
          <span>Load more</span>
        </button>
      </div> */}
      {/* <div className={styles.foot}>
        <button className={styles.arrow}>
          <Icon name="arrow-left" size="20" />
        </button>
        <div className={styles.numbox}>
        {
          [1,2,3,4].map((item,index) => (
            <span className={styles.btnnum} key={index} >{item}</span>
          ))
        }
        </div>
        <button className={styles.arrow}>
          <Icon name="arrow-right" size="20" />
        </button>
      </div> */}
    </div>
  );
};

export default Market;
