import styles from "./PriceControl.module.sass";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import { Empty, message } from 'antd';
import { useHistory } from "react-router";

// date
import utils from "../../../common";
import TextInput from "../../../components/TextInput";

const arrData = [
  {id:1,hasInput:false,salePrice:333,two_price:666,three_price:999},
  {id:2,hasInput:false,salePrice:334,two_price:665,three_price:998},
  {id:3,hasInput:false,salePrice:335,two_price:664,three_price:997},
  {id:4,hasInput:false,salePrice:336,two_price:663,three_price:996},
  {id:5,hasInput:false,salePrice:337,two_price:662,three_price:995},
  {id:6,hasInput:false,salePrice:338,two_price:661,three_price:994}
]


const PriceControl = ({ modl, value, goodsId, goodsNo, goodsName, isHas }) => {
  const routers = useHistory()
  const [priceList,setPrice] = useState(arrData);
  const [active, setActive] = useState(null);
  // const [hasInput, setInput] = useState(false);
  const [salePrice, setOne] = useState(false);
  // const [two_price, setTwo] = useState(false);
  // const [three_price, setThree] = useState(false);

  useEffect(()=>{
    api_priceList()
  },[modl,value,isHas])

  const api_priceList = ()=> {
    setActive(null)
    let params = {
      goodsId: goodsId,
      goodsName: goodsName, 
      goodsBarNo: goodsNo,
      searchFlag: modl == 'shop' ? 2 : 3
    }
    if(modl == 'shop'){
      params.merchantName = value
    }else{
      params.storeName = value
    }
    params = utils.dealElement(params);
    window.api('goods.getCustomizeGoods',params).then((res)=>{
      if(res.service_status == 'S'){
        setPrice([...res.merchantCustomizeGoodsInfos])
      }
    }).catch(()=>{
      setPrice([])
    })
  };

  // 修改价格
  const editPrice = (item, idx) => {
    setActive(idx)
    priceList[idx].hasInput = true
    setPrice(priceList)
    setOne(priceList[idx].salePrice)
    // setTwo(priceList[idx].two_price)
    // setThree(priceList[idx].three_price)
  }

  // 保存价格
  const submitPrice = (item, idx) => {
    setActive(null)
    priceList[idx].hasInput = false
    setPrice(priceList)
    api_agentModifyGoods(idx)
  }

  // 改价
  const api_agentModifyGoods = (idx) => {
    let obj = [],
    options = {
      goodsId: priceList[idx].goodsId,
      salePrice: salePrice,
    }
    if(modl == 'shop'){
      obj.push(priceList[idx].merchantNo)
      options.merchantCode = obj.toString()
    }else{
      obj.push(priceList[idx].storeNo)
		  options.storeCode = obj.toString()
    }
    window.api(('goods.agentModifyGoods'), options).then(res => {
      if(res.success){
        message.info('保存成功')
        api_priceList()
      }else{
        message.error('保存失败')
      }
    })
  }

  
  const changeText = (e, tag ) => {
    switch(tag)
      {
          case 'one':
              setOne(Number(e.target.value));
              break;
          // case 'two':
          //     setTwo(Number(e.target.value));
          //     break;
          // case 'three':
          //     setThree(Number(e.target.value));
          //     break;
          default:
      }
  }

  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>{modl == 'shop' ? '商户总部名称/编号' : '商户门店名称/编号'}</div>
            <div className={styles.col}>产品名称/编号</div>
            <div className={styles.col}>价格</div>
            {/* <div className={styles.col}>二年价格</div>
            <div className={styles.col}>三年价格</div> */}
            <div className={styles.col}>价格管理</div>
          </div>
          {
            priceList.length > 0 ?
            (
              priceList.map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    <div className={styles.colflex}>
                      <div className={styles.lefttxt}>
                        {modl == 'shop' ? x.merchantName : x.storeName}
                        <div className={styles.label}>{modl == 'shop' ? x.merchantNo : x.storeNo}</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.product}>{x.goodsName}</div>
                    <div className={styles.invoice}>{x.goodsBarNo}</div>
                  </div>
                  <div className={styles.col}>
                    {
                      active == index && x.hasInput ? (
                        <div className={styles.ac_input}>
                          <TextInput
                            className={styles.field}
                            type="number"
                            min="0"
                            currency="¥"
                            value={salePrice}
                            onChange={(e)=>changeText(e,'one')}
                          />
                        </div>
                      ) : <span> ¥{x.salePrice} </span>
                    }
                  </div>
                  {/* <div className={styles.col}>
                  {
                      active == index && x.hasInput ? (
                        <div className={styles.ac_input}>
                          <TextInput
                            className={styles.field}
                            type="number"
                            min="0"
                            currency="¥"
                            value={two_price}
                            onChange={(e)=>changeText(e,'two')}
                          />
                        </div>
                      ) : <span> ¥{x.two_price} </span>
                    }
                  </div>
                  <div className={styles.col}>
                  {
                      active == index && x.hasInput ? (
                        <div className={styles.ac_input}>
                          <TextInput
                            className={styles.field}
                            type="number"
                            min="0"
                            currency="¥"
                            value={three_price}
                            onChange={(e)=>changeText(e,'three')}
                          />
                        </div>
                      ) : <span> ¥{x.three_price} </span>
                    }
                  </div> */}
                  <div className={styles.col}>
                    {
                      active == index && x.hasInput ? (
                        <button className={cn('button', styles.btnwh)} onClick={() => submitPrice( x, index )}>保存价格</button>
                      ) : (
                        <button className={cn('button', styles.btnwh)} onClick={() => editPrice( x, index )}>修改价格</button>
                      )
                    }
                    
                    
                  </div>
                </div>
              ))
            )
            :
            (
              <div className={styles.empty}>
                <Empty />
              </div>
            )
          }
        </div>
      </div>
    </Card>
  );
};

export default PriceControl;
