import React, { useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../components/TooltipGlodal";

const Details = ({ onClose, fwsSource }) => {


  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          {/* <img className={styles.codeimg} src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" alt="企业微信二维码" />
          <span className={styles.graytxt}>扫码添加微信</span> */}
          <span className={styles.txt1}>{fwsSource.agentName}</span>
          <span className={styles.txt2}>{fwsSource.mobile}</span> 
          <span className={styles.graytxt}>联系您的服务商以获得最优惠的价格</span>
          <button className={cn("button", styles.button)}  onClick={onClose}>
            确定
          </button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Details;
