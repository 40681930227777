import React, { useEffect, useState } from "react";
import styles from "./SelectPrice.module.sass";
import cn from "classnames";
import Modal from "../../../components/Modal";
import SelectOutlet from "../SelectOutlet";
import Icon from "../../../components/Icon";

const SelectPrice = ({ modl, handleOutlet, pricsChangeEmit }) => {
  const [sellPrice, setSellPrice] = useState('')

  useEffect(()=>{
    pricsChangeEmit(sellPrice)
  },[sellPrice])

  const changeText = (e) => {
    setSellPrice(e.target.value)
  }

  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={cn("title-purple", styles.title)}>添加自定义{modl == 'shop' ? '总部' : '门店'}价格</div>
          <span className={styles.graytxt}>{modl == 'shop' ? '总部' : '门店'}价格将按照自定义价格执行</span>
          <div className={styles.big_box}>
            <div className={styles.boxBtn}>
              <div className={styles.boxint}>
                <img src="https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/money.png" title="" alt=""/>
                <div>
                  <span>售卖价格</span>
                  <div>
                    <input min="0" style={{'fontSize':'15px','fontWeight':600}} type="number" placeholder="点击添加" value={sellPrice} onChange={(e)=>changeText(e, modl)}></input>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.boxBtn}>
              <div className={styles.boxint} onClick={()=>{handleOutlet(true)}}>
                <img src="https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/use.png" title="" alt=""/>
                <div>
                  <span>选择{modl == 'shop' ? '总部' : '门店'}</span>
                  <div>点击选择</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPrice;
