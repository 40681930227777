import React, { useState } from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { message } from "antd";

const File = ({ className, label, tooltip, title ,onChange,value,style,fileTag}) => {
  const [picVal,setPicVal] = useState(value || '')
  const onInput = ()=>{
    let files = document.getElementById("file-pic-upload"+fileTag).files[0]
    let imgSize = (files.size / 1024).toFixed(2);
    if(imgSize > 2048){
      message.error('每张图片最大不超过2M');
      return false
    }
    const reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = ()=>{
      onChange(reader.result)
      setPicVal(reader.result);
    }
  }
  return (
    <div className={cn(styles.file, className)} style={style}>
      {label && (
          <div className={styles.label}>
            {label}{" "}
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place="right"
              />
            )}
          </div>
        )}
        <div className={styles.wrap} style={style}>
          <input className={styles.input} type="file" onInput={onInput} id={"file-pic-upload"+fileTag}/>
            {
              // picVal? picVal.indexOf('base64') ?
              // <div  className={styles.wrap} 
              //       style={{backgroundImage:'url('+picVal+')',backgroundRepeat:'no-repeat',backgroundSize:'contain',width:'100%'}}></div>:
              // <image className={styles.wrap} src={picVal}/>:
              <div className={styles.box}>
                <Icon name="upload" size="24" />
                    {title}
              </div>
            }
          </div>
    </div>
  );
};

export default File;
