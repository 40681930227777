import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Link, useHistory, useLocation } from "react-router-dom";
import aes from '../../api/aes/public';
import utils from "../../common";
import Icon from "../Icon";

const Page = ({ wide, children, title, isEdit }) => {
  const [visible, setVisible] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const router = useHistory()
  const query = useLocation()

  // const productDetail = (type) =>{
  //   router.push({
  //     pathname:"/products/add",
  //     state:{
  //       type:type,
  //     }
  //   })
  // };

  useEffect(()=>{
    window.scrollTo(0, 0);
    getKey()
  },[])

  const getKey = () => {
    if (query.search) {
      const key = utils.getQueryString(query.search).key;
      const res = JSON.parse(aes.Decrypt(key));
      loginCallback(res);
    }else{
      (localStorage.getItem('headParams') && localStorage.getItem('PKEY')) && setIsShow(true)
    }
  }

  // 登陆后的处理
  const loginCallback = (res) => {
    const headParams = {login_name: res.login_name, partner_id: res.partner_id};
    window.localStorage.setItem('fullName', res.full_name);
    window.localStorage.setItem('level', res.stock_level_id);
    window.localStorage.setItem('PKEY', res.partner_key);
    window.localStorage.setItem('identity', res.identity); // 登录者身份 0：平台 1：代理商 2：商户
    window.localStorage.setItem('headParams', JSON.stringify(headParams));
    setIsShow(true)
    // let url = ''
    // if(res.identity == 0){
    //   url = '/products/goodsList'
    // }else if(res.identity == 1){
    //   url = '/products/shop'
    // }else if(res.identity == 2 || res.identity == 3){
    //   url = '/products/shopProduct'
    // }else{
    //   url = '/products/signIn'
    // }
    // router.replace({
    //   pathname: url
    // })
  }

  return (
    <>
      <div className={styles.page}>
        {/* <Sidebar
          className={cn(styles.sidebar, { [styles.visible]: visible })}
          onClose={() => setVisible(false)}
        /> */}
        {/* <Header onOpen={() => setVisible(true)} /> */}
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            { 
              title && 
              <div className={cn("h3", styles.title)}>
                {title}
                {/* { isEdit && isEdit == 'products_y' ? 
                  (
                    <button className={cn("button", styles.button, styles.floatRight) } onClick={() => {productDetail(1)}}>
                      <Icon name="add" size="24" />
                      <span>添加商品</span>
                    </button>
                  ) : null
                } */}
              </div>
            }
            {
              isShow &&
              (children)
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Page);
