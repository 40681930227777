import React, { useEffect } from "react";
import styles from "./Statements.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import Transactions from "./Transactions";
import { useLocation } from "react-router";

const EarningsList = () => {
  const query = useLocation()
  const time = query.state ? query.state.time : '';
  const num = query.state ? query.state.num : '';

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  
  return (
    <>
      <div className={styles.section}>
        {/* <Overview className={styles.card} /> */}
        <Transactions time_num={num} time={time} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default EarningsList;
