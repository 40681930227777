import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Profile from "./Profile";
import Settings from "./Settings";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Filters from "../../components/Filters";
import Product from "../../components/Product";
import Follower from "./Follower";

// data
import { products } from "../../mocks/products";
import { followers } from "../../mocks/followers";
import utils from "../../common";
import { Empty } from "antd";

const categoryList = [{id:0,categoryTitle:'全部商品'},{id:1,categoryTitle:'自营产品'},{id:2,categoryTitle:'零售商品'},{id:3,categoryTitle:'外卖商品'}];
const intervals = ["Most recent", "Most new", "Most popular"];

const FwsShop = () => {
  const [activeId, setActiveId] = useState('-1');
  const [dataSource,setDataSource] = useState([]);
  const [categoryList,setCategory] = useState([]);
  // const [sorting, setSorting] = useState(intervals[0]);

  useEffect(()=>{
    api_getProdCategory()
  },[])

  useEffect(()=>{
    getGoodsList()
  },[activeId])

  useEffect(()=>{
  },[dataSource])

  const setActiveIdChange = (item)=> {
    setActiveId(item.id)
    
  };

  const getGoodsList = ()=> {
    let params = {
      page_size: 500,
      current_page: 1,
      status:0, // 商品状态（0：出售中；1：已下架）
      service_type:1, // 服务类型 0 老商户 1 服务市场
      goods_name: '',
      goods_bar_no: '',
      goods_category_id: activeId > 0 ? activeId : '',
      // goods_category_name: '',
      goods_property: 0, // 商品类型0 全部  1软件 2硬件 3 流量
    }
    params = utils.dealElement(params);
    window.api('goods.getgoodslist', params).then(res => {
      if(res.service_status == 'S'){
        setDataSource(res.goods_list)
      }
    }).catch((error) => {
      setDataSource([])
    });
  }

  // 获取商品分类
  const api_getProdCategory = ()=>{
    window.api('goods.getcategorylist', {}).then(res => {
      if(res.success){
        const optionsCate = [{value:'全部商品',id:'-1'}];
        let cate_arr = res.goods_category_list.filter((item)=>item.goods_category_hierarchy == 1 && item.service_type == 1)
        cate_arr.map(item=>{
          optionsCate.push({value:item.goods_category_name, id:item.id})
        });
        setCategory([ ...optionsCate ])
      }
    }).catch(() => {
      setCategory([])
    })
  }

  return (
    <>
      <div className={styles.shop}>
        {/* <div className={styles.background}>
          <img src="/images/content/bg-shop.jpg" alt="Background" />
        </div> */}
        <Card className={styles.card} classTitle={cn("title-purple", styles.title)} title="商品管理">
          {/* <Profile /> */}
          <div className={styles.control}>
            <div className={styles.nav}>
              {categoryList.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: x.id == activeId,
                  })}
                  onClick={() => setActiveIdChange(x)}
                  key={index}
                >
                  {x.value}
                </button>
              ))}
            </div>
            {/* <div className={styles.dropdownBox}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
              />
            </div>
            <Filters
              className={styles.filters}
              title="Showing 9 of 32 products"
            >
              <Settings />
            </Filters> */}
          </div>
          <div className={styles.wrap}>
            { activeId && (
              <>
                {
                  (dataSource || []).length > 0 ? (
                    <div className={styles.products}>
                      {(dataSource || []).map((x, index) => (
                        <Product
                            className={styles.product}
                            item={x}
                            key={index}
                            withoutСheckbox
                          />
                        ))
                      }
                    </div>
                  ) : (
                    <div className={styles.empty}>
                      <Empty />
                    </div>
                  )
                }
                
                
                {/* <div className={styles.foot}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Load more
                  </button>
                </div> */}
              </>
            )}

            {/* {activeId === 1 && (
              <>
                <div className={styles.followers}>
                  {followers.map((x, index) => (
                    <Follower
                      className={styles.follower}
                      item={x}
                      key={index}
                      followers
                    />
                  ))}
                </div>
                <div className={styles.foot}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Load more
                  </button>
                </div>
              </>
            )} */}

            {/* {activeId === 2 && (
              <>
                <div className={styles.followers}>
                  {followers.map((x, index) => (
                    <Follower
                      className={styles.follower}
                      item={x}
                      key={index}
                    />
                  ))}
                </div>
                <div className={styles.foot}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Load more
                  </button>
                </div>
              </>
            )} */}
          </div>
        </Card>
      </div>
    </>
  );
};

export default FwsShop;
