import React, { useState } from "react";
import cn from "classnames";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import ProductActivity from "./ProductActivity";
import ProductViews from "./ProductViews";
import Products from "./Products";

const FwsDashboard = () => {

  const [totalData,totalDataHangle] = useState({});
  const [timeType, setTimeType] = useState('');
  const [timeDate, setTimeDate] = useState([]);

  return (
    <>
      <div className={styles.section}>
        <Overview totalData={totalData} className={styles.card} stateTypeChange={(val) => setTimeType(val)} timeDateChange={(time) => setTimeDate(time)}/>
        {/* <div className={styles.row}>
          <div className={styles.col}>
            <ProductActivity />
          </div>
          <div className={styles.col}>
            <ProductViews />
          </div>
        </div> */}
        <Products timeType={timeType} timeDate={timeDate} totalDataChange={(val) => totalDataHangle(val)}/>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default FwsDashboard;
