import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Icon from "../Icon";
import { Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const Form = ({
  className,
  onSubmit,
  placeholder,
  value,
  setValue,
  type,
  name,
  icon,
}) => {
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown); // 添加全局事件
    return () => {
      window.removeEventListener('keydown', onKeyDown); // 销毁
    };
  },)

  const onKeyDown = (e) => {
    if(e.keyCode == 13){
      onSubmit()
    }
  }

  useEffect(()=>{
    if(value == ''){
      onSubmit()
    }
  },[value])

  return (
    <div className={styles.flexbox}>
      <div className={cn(className, styles.form)} action="">
        <input
          className={styles.input}
          type={type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          name={name}
          placeholder={placeholder}
          required
        />
        {
          value.length > 0 &&
          <button className={styles.clear} onClick={() => setValue('')}>
            <CloseCircleOutlined />
          </button>
        }
        <button className={styles.result}>
          <Icon name={icon} size="24" />
        </button>
      </div>
        {/* <Button shape="round" type="primary" onClick={onSubmit}>搜索</Button> */}
    </div>
    
  );
};

export default Form;
