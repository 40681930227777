import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import ProductsDashboard from "./screens/ProductsDashboard"; //平台-销售报表
import OrderList from "./screens/OrderList"; // 平台-订单管理
import Released from "./screens/Released"; // 平台-商品管理
import NewProduct from "./screens/NewProduct"; // 新建，编辑商品
import CategroyList from "./screens/CategroyList"; // 平台-分类管理

import FwsDashboard from "./screens/FwsDashboard"; //服务商-销售报表
import FwsOrderList from "./screens/FwsOrderList"; // 服务商-订单管理
import FwsShop from "./screens/FwsShop"; // 服务商-商品管理
import EarningsList from "./screens/EarningsList"; // 服务商-订单管理
import PayEarnings from "./screens/PayEarnings"; // 收益报表

import ShopProduct from "./screens/ShopProduct"; // 商户-商品管理
import ShopConfirm from "./screens/ShopConfirm"; // 商户-订单详情
import ShopOrderList from "./screens/ShopOrderList"; // 商户-订单列表
import PayOrder from "./screens/PayOrder"; // 商户-确认订单

import PageList from "./screens/PageList"; // 自主导航栏
import WyPage from "./screens/WyPage"; // 网银
import CheckStand from "./screens/CheckStand"; // 收银台
import SignUp from "./screens/SignUp"; // 登出
import SignIn from "./screens/SignIn"; // 登录

function App() {
 
  return (
    <Router>
      <Switch>
         {/* 平台 */}
        <Route
          exact
          path="/products/dashboard"
          render={() => (
            <Page>
              <ProductsDashboard />
            </Page>
          )}
        />
        <Route
          exact
          // path="/products/add/:type/:id"
          path="/products/add"
          render={() => (
            <Page>
              <NewProduct />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/goodsList"
          render={() => (
            <Page isEdit='products_y'>
              <Released />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/orderList"
          render={() => (
            <Page title="订单管理">
              <OrderList />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/categroyList"
          render={() => (
            <Page title="分类管理">
              <CategroyList />
            </Page>
          )}
        />

        {/* 代理商 */}
        <Route
          exact
          path="/products/shop"
          render={() => (
            <Page title="商品管理">
              <FwsShop />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/fwsOrderList"
          render={() => (
            <Page title="订单管理">
              <FwsOrderList />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/fwsDashboard"
          render={() => (
            <Page>
              <FwsDashboard />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/PayEarnings"
          render={() => (
            <Page title="服务市场报表">
              <PayEarnings />
            </Page>
          )}
        />
        <Route
          exact
          path="/products/EarningsList"
          render={() => (
            <Page title="收益详情">
              <EarningsList />
            </Page>
          )}
        />

        {/* 商户 */}
        <Route
          exact
          path="/products/shopProduct"
          render={() => (
              <ShopProduct />
          )}
        />
        <Route
          exact
          path="/products/shopConfirm"
          render={() => (
              <ShopConfirm />
          )}
        />
        <Route
          exact
          path="/products/shopOrderList"
          render={() => (
              <ShopOrderList />
          )}
        />
        <Route
          exact
          path="/products/payOrder"
          render={() => (
              <PayOrder />
          )}
        />

        {/* 收银台 */}
        <Route
          exact 
          path="/products/checkStand" 
          render={() => (
              <CheckStand />
          )}
        />
        
        {/* <Route exact path="/products/checkStand" component={CheckStand} /> */}
        {/* <Route exact path="/products/signUp" render={() => <SignUp />} /> */}
        <Route exact path="/products/signIn" render={() => <SignIn />} /> 
        <Route exact path="/products/pagelist" component={PageList} />
        <Route exact path="/products/wyPage" component={WyPage} />

        

      </Switch>
    </Router>
  );
}

export default App;
