import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import ModalProduct from "../../../../components/ModalProduct";

const Control = ({ className, lookChange, deleteChange }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  const actions = [
    {
      icon: "search",
      action: () => lookChange(),
    },
    {
      icon: "trash",
      action: () => deleteChange(),
    },
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Icon name={x.icon} size="20" />
          </button>
        ))}
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Control;
