import React, { useEffect, useState } from "react";
import styles from "./SelectOutlet.module.sass";
import cn from "classnames";
import Checkbox from "../../../components/Checkbox";
import { Select } from 'antd';
import utils from "../../../common";

const SelectOutlet = ({ modl, selsectChangeEmit}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [optionsValue, setOptionsValue] = useState([]);
  const [selectValue, setSelectValue] = useState('');

  // const compatibility = [];

  // const checkChange = (id) => {
  //   let arr = [];
  //   if (selectedFilters.includes(id)) {
  //     arr = selectedFilters.filter((x) => x !== id);
  //   } else {
  //     arr = [...selectedFilters, id];
  //   }
  //   setSelectedFilters(arr);
  // };

  useEffect(()=>{
    api_getItemList()
  },[selectValue])

  useEffect(()=>{
    selsectChangeEmit(selectedFilters, modl)
  },[selectedFilters])

  useEffect(()=>{

  },[optionsValue])

  // 请求数据
  const api_getItemList = ()=>{
    let params = {
      searchContent: selectValue || '',
      searchFlag: modl == 'shop' ? 2 : 3
    }
    params = utils.dealElement(params);
    window.api('eps.getMerchants', params).then(res => {
      if(res.success){
        let arr = []
        let obj = res.merchantList.forEach((i)=>{
          arr.push({
            value: modl == 'shop' ? i.merchantCode + '--' + i.nameCn : i.storeNo + '--' + i.storeFullNameCn,
            lable: modl == 'shop' ? i.merchantCode : i.storeNo
          })
        })
        setOptionsValue(arr)
      }
    }).catch(() => {
      setOptionsValue([])
    })
  }

  const handleChange = (label, value) => {
    setSelectedFilters(value)
  };

  const handleOnSearch = (value) => {
    setSelectValue(value)
  }

  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={cn("title-purple", styles.title)}>选择{modl == 'shop' ? '总部' : '门店'}</div>
          {/* <div className={styles.list}>
            {compatibility.map((x, index) => (
              <Checkbox
                className={styles.checkbox}
                content={x.title}
                value={selectedFilters.includes(x.id)}
                onChange={() => checkChange(x.id)}
                key={index}
              />
            ))}
          </div> */}
          <div className={styles.sele_box}>
            <Select
              bordered={false}
              size={'large'}
              mode="multiple"
              style={{
                width: '100%',
              }}
              placeholder="输入关键字搜索查询"
              defaultValue={[]}
              onChange={handleChange}
              onSearch={handleOnSearch}
              options={optionsValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectOutlet;
