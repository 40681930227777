import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox";
import Balance from "../../../../../components/Balance";
import ModalProduct from "../../../../../components/ModalProduct";
import Control from "../../Control";

import { numberWithCommas } from "../../../../../utils.js";

const Row = ({ item, value, onChange, up }) => {
  // const [visibleActions, setVisibleActions] = useState(false);
  // const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} 
      // onMouseLeave={() => setVisibleActions(false)}
      >
        {/* <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div> */}
        <div className={styles.col}>
          <div
            className={styles.item}
            // onClick={() => setVisibleModalProduct(true)}
          >
            <div className={styles.preview}>
              <img
                srcSet={`${`https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/image12.png`} 2x`}
                src={`https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/image12.png`}
                alt="产品图片"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.agentName}</div>
              <div className={styles.wrap}>
                <div className={styles.category}>{item.agentNo}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.col}>
          <div className={styles.label}>Status</div>
          {item.status ? (
            <div className={cn("status-green", styles.status)}>Active</div>
          ) : (
            <div className={cn("status-red", styles.status)}>Deactive</div>
          )} */}
          {/* <Control
            className={styles.control}
            visibleActions={visibleActions}
            setVisibleActions={setVisibleActions}
            up={up}
          /> */}
        {/* </div> */}
        <div className={styles.col}>{item.salesNum}</div>
        <div className={styles.col}>¥{item.salesAmt}</div>
        <div className={styles.col}>¥{item.platIncome}</div>
        <div className={styles.col}>¥{item.agentIncome}</div>
        {/* <div className={styles.col}>
          <div className={styles.label}>Views</div>
          <div className={styles.box}>
            <div className={styles.number}>
              {(item.views / 1000).toFixed(0)}k
            </div>
            <div className={styles.line}>
              <div
                className={styles.progress}
                style={{
                  backgroundColor: "#2A85FF",
                  width: item.viewsPercent,
                }}
              ></div>
            </div>
          </div>
        </div> */}
        {/* <div className={styles.col}>
          <div className={styles.label}>Likes</div>
          <div className={styles.box}>
            <div className={styles.number}>{item.likes}</div>
            <div className={styles.line}>
              <div
                className={styles.progress}
                style={{
                  backgroundColor: "#8E59FF",
                  width: item.likesPercent,
                }}
              ></div>
            </div>
          </div>
        </div> */}
      </div>
      {/* <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      /> */}
    </>
  );
};

export default Row;
