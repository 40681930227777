import React, { useEffect, useState } from "react";
import styles from "./Transactions.module.sass";
import cn from "classnames";
import { numberWithCommas, getTimestamp, getToDay, yesterday } from "../../../utils.js";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Dropdown from "../../../components/Dropdown";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Details from "./Details";
import { useHistory } from "react-router";
import { ConfigProvider, DatePicker, Empty, Space } from "antd";
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

// date
import { transactions } from "../../../mocks/transactions";
import FwsOrderList from "..";
import utils from "../../../common";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";
// import Icon from "../../../components/Icon";

const orderState = ["全部", "未支付", "已完成", "已取消"];
const timeArr = ["今日", "昨日", "自定义时间"];

// const navigation = ["商户订单"];

const parameters = [
  
];

const shopInfo = [
  {
    title: "区域",
    content: "北区",
  },
  {
    title: "商户名称",
    content: 234,
  },
  {
    title: "商户编号",
    content: 99988876543,
  },
  {
    title: "商户版本",
    content: "至尊版MVP",
  },
  {
    title: "服务商名称",
    content: "孙悟空",
  },
  {
    title: "服务商编号",
    content: "13737173891318",
  },
  {
    title: "服务商员工名称",
    content: "阿世界",
  },
  {
    title: "服务商员工编号",
    content: "000976",
  },
  {
    title: "扩展信息1",
    content: "2022",
  },
  {
    title: "扩展信息2",
    content: "2022",
  },
];

const Transactions = ({ time_num, time }) => {
  const routers = useHistory()
  const { RangePicker } = DatePicker;
  const [sorting, setSorting] = useState(orderState[0]);
  const [timeing, setTimeing] = useState(timeArr[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [search, setSearch] = useState("");
  const [orderDetail, setOrderDetail] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [dateString,setDateString] = useState([]);
  // const [isPage,setIsPage] = useState(0);

  const gobackPage = () => {
    routers.goBack(-1)
  }

  useEffect(() => {
    if(timeing != '自定义时间' || dateString.length > 1){
      getFwsOrderList();
    }
  }, [sorting, activeIndex, timeing, dateString]);

  useEffect(() => {}, [dataSource]);

  const setSearchChange = (e) => {
    setSearch(e);
  };

  const orderStateChange = (item, idx) => {
    setSorting(item);
  };

  const timeStateChange = (item, idx) => {
    setTimeing(item)
  }

  const setActiveIndexChange = (item, idx) => {
    setActiveIndex(idx);
  };

  const getFwsOrderList = (num, flag) => {
    // if(flag && isPage <= 8){
    //   return false
    // }
    let params = {
      page_size: 500,
      // current_page: 1,
      order_no: search || "",
      agent_no:"",
      service_type: 1,
      // next: flag == 'l' ? num : '',
      // previous: flag == 'r' ? num : '',
    };
    
    let searchStatus = ""; //订单状态：0:未支付，3:已完成，4:已取消
    if (sorting == "未支付") {
      searchStatus = 0;
    } else if (sorting == "已完成") {
      searchStatus = 3;
    } else if (sorting == "已取消") {
      searchStatus = 4;
    }
    // params.status = searchStatus;
    params.status = 3;
    if(timeing == '今日' && time_num == 1){
      params.start_time = getToDay() + '00:00:00'
      params.end_time = getToDay() + '23:59:59'
    }else if(timeing == '昨日'){
      params.start_time = yesterday() + '00:00:00'
      params.end_time = yesterday() + '23:59:59'
    }else if( time_num == 2 ){
      params.start_time = utils.getDate(time, 1) + " " + '00:00:00'
      params.end_time = utils.getDate(time, 1) + " " + '23:59:59'
    }else{
      params.start_time = dateString[0] + ":00"
      params.end_time = dateString[1] + ":00"
    }


    params = utils.dealElement(params);
    window
      .api("order.orderList", params)
      .then((res) => {
        if (res.service_status == "S") {
          setDataSource(res.orders);
          // setIsPage(res.orders.length)
        }
      })
      .catch((error) => {
        setDataSource([])
      });
  };

  const modalDetail = (item, index) => {
    setOrderDetail(item);
    setVisibleModal(true);
  };

  // 自定义时间
  const onChangeTimes = (value, dateString) => {
    setDateString(dateString)
  };

  const onOkTimes = (value) => {
   
  };

  const okChange = (num) => {
    setVisibleModal(false);
  };

  return (
    <Card
      className={cn(styles.card)}
      classCardHead={styles.head}
      title={time_num == 1? '收益（今日）' : '收益' + '(' + (utils.getDate(time, 3)) + ')'}
      classTitle={cn("title-blue", styles.title)}
      head={
        <>
          {/* <button className={cn("button-small", styles.button)}>
            Download SCV
          </button> */}
          {/* <div className={styles.nav}>
            {navigation.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndexChange(x, index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div> */}

          <Form
            className={(styles.form, styles.formwid)}
            value={search}
            setValue={(e) => setSearchChange(e)}
            onSubmit={() => getFwsOrderList()}
            placeholder="搜索订单"
            type="text"
            name="search"
            icon="search"
          />

          <div className={styles.boxdown}>
            <div className={styles.flexbox}>
              <div className={styles.times}>
                {
                  timeing == '自定义时间' ? (
                    
                      <Space direction="vertical" size={12}>
                        <ConfigProvider locale={locale}>
                          <RangePicker
                            showTime={{
                              format: 'HH:mm',
                            }}
                            placeholder={['选择起始日期时间','选择截止日期时间']}
                            format="YYYY-MM-DD HH:mm"
                            onChange={onChangeTimes}
                            onOk={onOkTimes}
                          />
                        </ConfigProvider>
                      </Space>
                  ) : null
                }
              </div>
              {/* <Dropdown
                className={styles.dropdown2}
                classDropdownHead={styles.dropdownHead}
                value={timeing}
                setValue={(item, idx) => timeStateChange(item, idx)}
                options={timeArr}
                small
              /> */}
              {/* <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={(item, idx) => orderStateChange(item, idx)}
                options={orderState}
                small
              /> */}
              <div className={cn("button-stroke button-small", styles.button)} onClick={()=>gobackPage()}>
                <Icon name="arrow-left" size="24" />
                <span className={styles.goback}>返回</span>
              </div>
            </div>
          </div>
        </>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.col}>商户名称/编号</div>
            <div className={styles.col}>订单状态</div>
            <div className={styles.col}>购买商品</div>
            <div className={styles.col}>数量</div>
            <div className={styles.col}>订单金额</div>
            <div className={styles.col}>本单收益</div>
            <div className={styles.col} style={{'min-width':'120px'}}>销售员</div>
            <div className={styles.col}>下单时间</div>
            <div className={styles.col}>订单操作</div>
          </div>
          {(dataSource || []).length > 0 ? (
            dataSource.map((x, index) => (
              <div className={styles.row} key={index}>
                <div className={styles.col}>
                  <div className={styles.colflex}>
                    <img
                      srcSet={`${x.order_details[0].goods_pic} 2x`}
                      src={x.order_details[0].goods_pic}
                      alt="产品图片"
                    />
                    <div className={styles.lefttxt}>
                      {x.agent_name}
                      <div className={styles.label}>{x.order_no}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  {/* <div className={styles.details}>
                    <div className={styles.product}>{x.product}</div>
                    <div className={styles.invoice}>{x.invoice}</div>
                  </div> */}
                  {x.status == 0 ? (
                    <div
                      className={cn(
                        { "status-red-dark": x.status == 0 },
                        styles.status
                      )}
                    >
                      待付款
                    </div>
                  ) : null}
                  {x.status == 3 ? (
                    <div
                      className={cn(
                        { "status-green-dark": x.status == 3 },
                        styles.status
                      )}
                    >
                      已完成
                    </div>
                  ) : null}
                  {x.status == 4 ? (
                    <div
                      className={cn(
                        { "status-yellow": x.status == 4 },
                        styles.status
                      )}
                    >
                      已取消
                    </div>
                  ) : null}
                </div>
                <div className={styles.col}>
                  {x.order_details[0].goods_name}
                </div>
                <div className={styles.col}>
                  {x.order_details[0].goods_qty}
                </div>
                <div className={styles.col}>¥{x.total_amt || 0}</div>
                <div className={styles.col}>
                  ¥{x.order_extend?.agentEarning || 0}
                </div>
                <div className={styles.col} style={{'min-width':'120px'}}>
                  {x.agent_salesman_name}
                </div>
                <div className={styles.col}>
                  {x.order_details[0].gmt_created}
                </div>
                <div className={styles.col}>
                  <button
                    className={cn("button", styles.btnwh)}
                    onClick={() => modalDetail(x, index)}
                  >
                    订单详情
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.empty}>
              <Empty />
            </div>
          )}
        </div>
      </div>
      {/* {
        (dataSource || []).length > 0 && 
        <div className={styles.foot}>
          <button className={styles.arrow} onClick={() => getFwsOrderList(dataSource?.shift().order_no, 'l')}>
            <Icon name="arrow-left" size="20" />
          </button>
          {isPage}
          <button className={styles.arrow} onClick={() => getFwsOrderList(dataSource?.pop().order_no, 'r')}>
            <Icon name="arrow-right" size="20" />
          </button>
        </div>
      } */}
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details
          parameters={parameters}
          itemDetail={orderDetail}
          orderDetail={orderDetail?.order_details ? orderDetail?.order_details[0] : {}}
          onClose={() => setVisibleModal(false)}
          isOK={(val) => okChange(val)}
        />
      </Modal>
    </Card>
  );
};

export default Transactions;
