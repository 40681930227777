import { ConsoleSqlOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import styles from "./CategroyList.module.sass";
import ShopList from "./ShopList";
import SystemList from "./SystemList";

const  CategroyList = ({}) => {
    const [systemList, setSystemList] = useState([]); // 服务市场分类
    const [shopList, setShopList] = useState([]); // 商城分类

    useEffect(() => { 
        // api_getProdCategory()
    }, [])

    useEffect(() => {
        
    },[systemList, shopList])

    // 获取商品分类
    // const api_getProdCategory = ()=>{
    //     window.api('goods.getcategorylist', {}).then(res => {
    //     if(res.success){
    //         let cate_system = res.goods_category_list.filter((item)=>item.goods_category_hierarchy == 1 && item.service_type == 1 )
    //         let cate_shop = res.goods_category_list.filter((item)=>item.goods_category_hierarchy == 1 && item.service_type != 1 )
            
    //         cate_system.forEach((e)=>{
    //             e.text = e.goods_category_name
    //             e.id = e.id.toString()
    //         })
    //         setSystemList([ ...cate_system ])

    //         cate_shop.forEach((e)=>{
    //             e.text = e.goods_category_name
    //             e.id = e.id.toString()
    //         })
    //         setShopList([ ...cate_shop ])
    //     }
    //     }).catch(() => {
            
    //     })
    // }

    return (
        <>
            <div className={styles.item}>
                <SystemList systemList={systemList}/>
            </div>
            {/* <ShopList shopList={shopList}/> */}
        </>
    )
}
export default CategroyList