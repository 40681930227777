import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Tooltip from "../../../components/Tooltip";
import Balance from "../../../components/Balance";
import Chart from "./Chart";
import { DatePicker, Space, ConfigProvider } from 'antd';
import { numberWithCommas, getTimestamp } from "../../../utils.js";
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

const intervals = ["今日", "昨日", "自定义时间"];

const Overview = ({ totalData, className, stateTypeChange, timeDateChange }) => {
  const { RangePicker } = DatePicker;

  const items = [
    {
      title: "销售金额",
      counter: totalData.salesAmt,
      icon: "activity",
      value: 88.8,
      background: "#edf8f2",
      chartColor: "#83BF6E",
      data: [
        {
          name: "1",
          earning: 500,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1100,
        },
        {
          name: "4",
          earning: 900,
        },
        {
          name: "5",
          earning: 1300,
        },
        {
          name: "6",
          earning: 800,
        },
        {
          name: "7",
          earning: 1000,
        },
      ],
    },
    {
      title: "销售数量",
      counter:  totalData.salesNum,
      icon: "shopping-bag",
      value: -88.8,
      background: "#ecf9fe",
      chartColor: "#2A85FF",
      data: [
        {
          name: "1",
          earning: 1300,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1000,
        },
        {
          name: "4",
          earning: 500,
        },
        {
          name: "5",
          earning: 800,
        },
        {
          name: "6",
          earning: 1100,
        },
        {
          name: "7",
          earning: 900,
        },
      ],
    },
    {
      title: "产生收益",
      counter: totalData.agentIncome,
      icon: "payment",
      value: 88.8,
      background: "#f2efff",
      chartColor: "#8E59FF",
      data: [
        {
          name: "1",
          earning: 1200,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1300,
        },
        {
          name: "4",
          earning: 600,
        },
        {
          name: "5",
          earning: 1300,
        },
        {
          name: "6",
          earning: 800,
        },
        {
          name: "7",
          earning: 1000,
        },
      ],
    },
  ];

  const [sorting, setSorting] = useState(intervals[0]);
  const [isTime, setIsTime] = useState(false);

  const setSortingChange = (x, idx)=> {
    if(x != '自定义时间'){
      setIsTime(false)
    }else{
      setIsTime(true)
    }
    setSorting(x)
    // getDataList()
  }
  
  useEffect(()=>{
    // getDataList()
    if(!isTime){
      timeDateChange([])
      stateTypeChange(sorting)
    }
  },[sorting])

  // 自定义时间
  const onChangeTimes = (value, dateString) => {
    timeDateChange(dateString)
  };

  const onOkTimes = (value) => {
    
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="收益概览"
        classTitle="title-purple"
        head={
          <>
            <div className={styles.times}>
              {
                isTime ? (
                  
                    <Space direction="vertical" size={12}>
                      <ConfigProvider locale={locale}>
                        {/* <RangePicker
                          showTime={{
                            format: 'HH:mm',
                          }}
                          placeholder={['选择起始日期时间','选择截止日期时间']}
                          format="YYYY-MM-DD HH:mm"
                          onChange={onChangeTimes}
                          onOk={onOkTimes}
                        /> */}
                        <RangePicker onChange={onChangeTimes}/>
                      </ConfigProvider>
                    </Space>
                ) : null
              }
            </div>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={(x,idx) => setSortingChange(x,idx)}
              options={intervals}
              small
            />
          </>
        }
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {x.title}
                      <Tooltip
                        className={styles.tooltip}
                        title="就是这么牛"
                        icon="info"
                        place="right"
                      />
                    </div>
                    <div className={styles.counter}>{x.counter}</div>
                    <div className={styles.indicator}>
                      {/* <Balance className={styles.balance} value={x.value} /> */}
                      <span>{sorting}</span>
                    </div>
                  </div>
                  <Chart className={styles.chart} item={x} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
