import React, { useEffect, useRef, useState } from "react";
import { Tabs, Typography, Image, message, Button, Empty, Spin } from "antd";
import { useLocation, useHistory } from "react-router";
import { Link } from "react-router-dom";
import styles from "./PageList.module.sass";
import utils from "../../common";
import Card from "../../components/Card";
import aes from '../../api/aes/public';
import { getSumJsPay, getOrderDetail } from "../../api/openApi";
import { getNowFormatDate, getDateStr } from "../../utils";

const CheckStand = () => {
  const router = useHistory()
  const query = useLocation()
  const [dataSource,setDataSource] = useState({});
  const [orderDetail,setOrderDetail] = useState({});
  const [activeKey, setActiveKey] = useState("");
  const [count, setCountHandle] = useState();
  const [qrCode, setqrCode] = useState("");
  const [activeImg, setactiveImg] = useState(0); // 0个人，1企业
  const [hasPay, setHasPay] = useState('') // 是否支付
  const [isPayOk, setisPayOk] = useState('') // 支付成功S/失败F 
  const instance = useRef({timer: 0});
  const instance2 = useRef({timer2: 0});
  const instance3 = useRef({timer3: 0});

  const { Title } = Typography;
  const size = useState("Large");
  
  const getUrl = () => {
    let arrUrl = '';
    if (window.location.hostname === 'localhost') {                // 本地
      arrUrl = 'http://localhost:8080' 
    } else if( window.location.hostname === 'preeps.51ebill.com'){  //测试
      arrUrl = 'https://preeps.51ebill.com'
    } else if( window.location.hostname === 'inteps.liantuofu.com'){  // 商户端--灰度
      arrUrl = 'https://inteps.liantuofu.com'
    } else if( window.location.hostname === 'inteps.liantuobank.com'){  //平台/代理商--灰度                                                    
      arrUrl = 'https://inteps.liantuobank.com'
    } else if( window.location.hostname === 'neweps.liantuofu.com'){  // 商户端生产
      arrUrl = 'https://neweps.liantuofu.com'
    } else if( window.location.hostname === 'neweps.liantuobank.com'){  //平台/代理商生产                                                      
      arrUrl = 'https://neweps.liantuobank.com'
    }
    return arrUrl;
  }

  //随机数
  const randomNum = (num) => {
    let rand = [];
    for (let i = 0; i <= num; i++) rand.push(Math.floor(Math.random() * 10));
    return rand.join("");
  };
  
  useEffect(() => {
    getKey()
  }, []);

  useEffect(() => {
    tabType()
    orderStatus(dataSource)
    return () => {
        clearTimeout(instance.current.timer);
        clearTimeout(instance2.current.timer2);
    }
  },[dataSource, activeKey])

  useEffect(() => {

  }, [count,hasPay,orderDetail]);


  // 🌰http://localhost:8080/products/checkStand?key=YzRddJrQFq3Hvk0M2sXJ+ma1M1dkCyLzIM3GdJ5JFNpE/xnJDnlPHiCVgbXuuXlh1yWn/YnqRmIgh5We9ZHt94FMI6216drDKb3n0uTRKtk=
  //获取url携带的参数 
  const getKey = () => {
    if (query.search) {
      const key = utils.getQueryString(query.search).key;
      const res = JSON.parse(aes.Decrypt(key));
      getPayData(res) // 组装支付请求
    }
  }

  // 组装支付请求
  const getPayData = (res)=> {
    let { order_no, total_amt, payState } = res
    let params = {
      order_no: order_no,
      service_type: 1,
      // payState: payState , // 来源为-立即购买，1   订单去支付，2
      total_amount: total_amt,
    }
    params = utils.dealElement(params);
    window.api('order.goPayment',params).then(resp=>{
      setDataSource({ ...resp })
      window.localStorage.setItem('key', resp.shop_app_key);
      if( resp.order_amt >= 1000 ){ // 支付金额大于1000 使用网银 否者 聚合支付
        setActiveKey('2'); // 网银
      }else{
        setActiveKey('1'); // 聚合
      }
    }).catch(()=>{
      
    })
    
  };

  // 切换tab
  const onChangeTab = (key) => {
    setActiveKey(key);
  };

  // 切换tab后
  const tabType = () => {
    if( !dataSource.core_merchant_no ){
      return false
    }
    if (activeKey == 1) {
      sumJsPay(dataSource);
    }else{
      // sumWyPay(dataSource)
    }
  }

  // 订单查询结果
  const orderStatus = (data) => {
    if( !data.core_merchant_no ){
      return false
    }
    if (activeKey == 1) {
      getPayStatus_Q(data);
    }else{
      // getPayStatus_W(data);
    }
  }

  // 聚合支付
  const sumJsPay = async (data) => {
    let param = {
      appId: data.core_merchant_no, // 合作方标识
      random: randomNum(6), 
      merchantCode: data.merchant_no, // 门店编号
      expireSeconds: 300, // 二维码有效期以秒为单位
      outTradeNo: data.out_trade_no_waqjhzf, // 商户订单号
      totalAmount: data.order_amt, // 订单总金额
      operatorId: "", // 操作员id
      orderRemark: data.goods_short_name + '/' + data.pay_merchant_code, // 订单备注
      returnUrl: "",
      notifyUrl: "", // 通知地址
      orderSource: "", // 订单来源
    };
    let result = await getSumJsPay({ ...param });
    if (result.code == "SUCCESS") {
      // message.success("老乡别走，老乡别走吗v");
      if(!count || count == 0){
        let num = result.expireSeconds;
        setInterval(() => {
          if (num > -1) {
            setCountHandle(num--);
          } else {
            clearInterval();
          }
        }, 1000);
      }
      setqrCodeChange(result.url);
    }else{
      message.error(result.subMsg);
    }
  };

  // 生成聚合码
  const setqrCodeChange = (url) => {
    let qrUrl = `https://shopcashier.liantuobank.com/ShopCashier/qr.in?data=${url}`;
    setqrCode(qrUrl);
  };

  //聚合-支付结果查询
  const getPayStatus_Q = async (resp) => {
    let param = {
      appId: resp.core_merchant_no, // 合作方标识
      random: randomNum(6), 
      merchantCode: resp.merchant_no, // 门店编号
      outTradeNo: resp.out_trade_no_waqjhzf, // 商户订单号
      outTransactionId: '', // 第三方订单号
      operatorId: "", // 操作员id
    };
    let result = await getOrderDetail({ ...param });
    // 交易状态 NOTPAY未支付 SUCCESS支付成功 REFUND转入退款 CLOSED已关闭 REVOKED已撤销
    if(result.code == "SUCCESS") { // 订单查询成功
      setHasPay(true) 
      if(result.orderStatus == "SUCCESS"){
        getOrderStatus(result) // 推送订单状态
        setisPayOk('S') // 支付成功
      }else if (result.orderStatus == "NOTPAY" || result.orderStatus == "CLOSED" || result.orderStatus == "REVOKED"){
        setisPayOk('F') // 支付失败
      }
      const str_ls = result.payTime || '';
      orderDetail.payTime = str_ls.substr(0, 4) + '-' + str_ls.substr(4, 2) + '-' + str_ls.substr(6, 2) + ' ' + str_ls.substr(8, 2) + ':' + str_ls.substr(10, 2) + ':' + str_ls.substr(12, 2);
      
      setOrderDetail({...result, ...orderDetail})
      
      instance3.current.timer3 = setTimeout(()=>{
        clearTimeout(instance3.current.timer3);
        goBackPage()
      },5000)
    }else{
      setHasPay(false)
      instance.current.timer = setTimeout(()=>{
        getPayStatus_Q(resp)
      },3000)
    }
  };

  // 网银-支付结果查询
  const getPayStatus_W = (resp)=> {
    let params = {
      pay_order_no: resp.out_trade_no_bpzf,
      total_amount: resp.order_amt,
      trade_status: '',
      pay_date:'',
      service_type: 1,
      test:1
    }
    params = utils.dealElement(params);
    window.api('order.onlinePaymentQuery',params).then(data=>{
      if(data.success){ // 成功结果
        getOrderStatus(resp)
      }else{
        instance2.current.timer2 = setTimeout(()=>{
          getPayStatus_W(resp)
        },3000)
      }
    }).catch(()=>{
      
    })
    
  };

  // 订单支付状态推送
  const getOrderStatus = (resp)=> {
    let params = {
      // pay_order_no: activeKey == 1 ? resp.out_trade_no_waqjhzf : resp.out_trade_no_bpzf ,
      pay_order_no: dataSource.out_trade_no_waqjhzf,
      out_trade_no: dataSource.out_trade_no, 
      total_amount: resp.order_amt,
      trade_status: 'SUCCESS',
      service_type: 1,
      pay_date:getNowFormatDate(0)
    }
    params = utils.dealElement(params);
    window.api('order.receptionPaymentResult',params).then(res=>{
      
    }).catch((res)=>{
      
    })
  };

  // 返回商家
  const goBackPage = () => {
    window.location.replace(getUrl() + '/products/shopProduct')
    // message.info('返回商家按钮');
  }

   // 网银支付
  const sumWyPay = (data) => {
    const tempwindow = window.open('_blank');
    let params = { 
      channel_type:'BP', // 支付渠道
      merchant_no: data.merchant_no, // 门店商户编号
      out_trade_no: data.out_trade_no_bpzf, // 商户订单号
      total_amount: data.order_amt, // 订单金额
      pay_time: getNowFormatDate(0), // 请求支付时
      goods_short_name: data.goods_short_name, // 商品简称
      body:'11111', // 商品描述
      order_type:'P', // 订单类型
      card_type:'', // 卡类型
      gate_type: activeImg == 0 ? '01' : '02' , // 网关支付类型 01: 个人网关，02: 企业网关，不传值，默认：01
      req_type:'01', // 请求来源
      time_start:'', // 交易起始时间
      time_expire:'', // 交易结束时间
      remark: data.goods_short_name + '/' + data.pay_merchant_code, // 备注
      spbill_create_ip:'127.0.0.1', // 终端IP  //
      operator_id:'', // 商户操作员编号
      device_info:'', // 设备号
      device_type: 4 , // 支付设备类型
      front_url: getUrl() + '/products/shopProduct', // 商户自定义跳转地址
      notify_url:'', // 异步通知地址
      pay_channel:'', // 支付通道
      pay_transaction_id:'', // 支付配置编号
      cashier_id:'', // 收银员ID
      cashier_login_name:'', // 收银员登录名
     }
     params = utils.dealElement(params);
      window.api('order.onlinePayment', params).then(res => {
      if(res.service_status == 'S'){
        // router.push({ 
        //   pathname: '/products/wyPage',
        //   state:{
        //     htmlContent: res.form_html,
        //   }
        //  })

        tempwindow.location = 'http://' + res.cashier_desk_url

        // window.open('http://' + res.cashier_desk_url ,'_blank');
  
        window.location.replace(getUrl() + '/products/shopProduct')
      }else{
        message.error({
          content: res.message,
          className: 'custom-class',
          style: {
            marginTop: '20vh',
          },
        },3);
      }
    }).catch(() => {

    });
  }


  return (
    <div className={styles.shop}>
      <div className={styles.title_felx}>
        <div className={styles.header}>
          <img src={
              "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/logo.a9a5d040.png"
            }
            alt=""
          />
          <div className={styles.store_shop}>收银台</div>
        </div>
        <div>
          <Title level={5}>收款平台编码：{dataSource.merchant_no}</Title>
          <Title level={5}>收款平台名称：{dataSource.merchant_name}</Title>
        </div>
      </div>
      {
        dataSource.core_merchant_no ?
        (
          <Card className={styles.card}>
        <div className={styles.topHead}>
          
          {
            !hasPay ?
            (
              <>
                <img src={ "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/duihao.png" } alt="" />
                <div className={styles.topbox}>
                  <Title level={4}>订单提交成功，快快支付吧！</Title>
                  <div className={styles.topright}>
                    <div>业务订单编号：{dataSource.out_trade_no}</div>
                    <div className={styles.toptxt}>
                      待支付:<span>{dataSource.order_amt}</span>
                    </div>
                  </div>
                </div>
              </>
            ) : null
          }
          {
            hasPay ? 
            (
              <>
                <div className={styles.topbox}>
                  <div className={styles.topright}>
                    {
                      isPayOk == 'S' &&
                      <>
                        <img src={ "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/duihao.png" } alt="" />
                        <Title style={{marginBottom:'0px'}} level={4}>订单支付成功！</Title>
                      </>
                    }
                    {
                      isPayOk == 'F' &&
                      <>
                        <img src={ "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/erro.png" } alt="" />
                        <Title style={{marginBottom:'0px'}} level={4}>订单支付失败！</Title>
                      </>
                    }
                    <div className={styles.toptxt}>
                      订单金额:<span>¥{orderDetail.totalAmount}</span>
                    </div>
                  </div>
                </div>
              </>
            ) : null
          }
        </div>
        <div className={styles.tabsStyle}>
          {
            !hasPay &&
            <>
              <Tabs
                tabBarGutter={30}
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={onChangeTab}
                type="card"
                size={size}
                centered
              >
                <Tabs.TabPane
                  tab={
                    <span style={{ fontSize: 16, padding: "0 40px" }}>
                      微信/支付宝/云闪付等平台
                    </span>
                  }
                  key="1"
                >
                  <div className={styles.tabbox}>
                    <Title level={5}>支付订单编号：{dataSource.out_trade_no_waqjhzf}</Title>
                    <Image width={200} style={{ margin: "30px 0" }} src={qrCode} />
                    <div className={styles.imgtxt}>
                      {
                        count > 0 ?
                          (
                            <>
                              <img src="https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/tishi.png"></img>
                              <div>
                                <div>
                                  请使用微信/支付宝云闪付APP扫码使用，<span className={styles.count}>请勿重复扫码支付</span>
                                </div>
                                <div>请在
                                  <span className={styles.count}> {count} </span>
                                  秒内完成支付，否则当前支付码将失效需重新发起支付或切换为网银支付
                                </div>
                              </div>
                            </>
                          ): null
                      }
                      {
                        count < 1 ?
                          (
                            <>
                              <img src="https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/tishi.png"></img>
                              <div className={styles.msginfo}>
                                <div>二维码已失效点击重新发起支付</div>
                                  <Button onClick={() => sumJsPay(dataSource)} style={{height:'42px', padding:'8px 40px', fontSize:'15px'}} type="primary" shape="round" size={size}>
                                    重新支付
                                  </Button>
                              </div>
                            </>
                          ): null
                      }
                    </div>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <span style={{ fontSize: 16, padding: "0 40px" }}>
                      网银支付
                    </span>
                  }
                  key="2"
                >
                  <div className={styles.tabbox}>
                    <Title level={5}>支付订单编号：{dataSource.out_trade_no_bpzf}</Title>
                    <div className={styles.wyimg}>
                        <img onClick={()=>setactiveImg(0)} className={activeImg == 0 ? styles.img_ac : ''} src={ "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/person_company_icon.png" } alt="" />
                        <img onClick={()=>setactiveImg(1)} className={activeImg == 1 ? styles.img_ac : ''} src={ "https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/netbank_company_icon.png" } alt="" />
                    </div>
                    {
                      activeImg == 1 &&
                      <div className={styles.imgtxt}>
                        <img src="https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/tishi.png"></img>
                        <div className={styles.msginfo}>
                          <div>企业网银商家需额外支付<span className={styles.count}> 10元 </span>手续费，商家实收减少！</div>
                        </div>
                      </div>
                    }

                    <div className={styles.btn}>
                      <Button onClick={() => sumWyPay(dataSource)} style={{height:'50px', padding:'12px 60px', fontSize:'17px', fontWeight:500}} type="primary" shape="round" size={size}>
                              立即支付
                      </Button>
                    </div>
                    
                  </div>
                  
                </Tabs.TabPane>
              </Tabs>
            </>
          }
          {
              hasPay &&
              <>
              <div style={{width:'100%'}}>
                <div className={styles.parameter}>
                  <div className={styles.title}>
                  订单编号：
                  </div>
                  <div className={styles.content}>{orderDetail.outTradeNo}</div>
                </div>
                {
                  isPayOk == 'S' ?
                  (
                    <>
                      <div className={styles.parameter}>
                        <div className={styles.title}>
                        支付时间：
                        </div>
                        <div className={styles.content}>{orderDetail.payTime}</div>
                      </div>
                      <div className={styles.parameter}>
                        <div className={styles.title}>
                        微信/支付宝/云闪付订单号：
                        </div>
                        <div className={styles.content}>{orderDetail.transactionId}</div>
                      </div>
                      <div className={styles.parameter}>
                        <div className={styles.title}>
                        支付方式：
                        </div>
                        <div className={styles.content}>{orderDetail.payType == 'WXPAY' ? '微信' : '支付宝'}</div>
                      </div>
                    </>
                  ) : null
                }
                {
                  isPayOk == 'F' ?
                  (
                    <div className={styles.parameter}>
                      <div className={styles.title}>
                      失败原因：
                      </div>
                      <div className={styles.content}>{orderDetail.subMsg}</div>
                    </div>
                  ) : null
                }
              </div>
              <div className={styles.footer_btn}>
              <Button onClick={() => goBackPage()} style={{height:'42px', padding:'8px 40px', fontSize:'15px'}} type="primary" shape="round" size={size}>
                返回商家
              </Button>
              </div>
              </>
          }
        </div>
          </Card>
        ) : 
        (
          <div className={styles.empty}>
            {/* <Empty /> */}
            <Spin size="large" />
          </div>
        )
      }
      
    </div>
  );
};

export default CheckStand;
