import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./PayEarnings.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import PayHistory from "./PayHistory";
import utils from "../../common";

const PayEarnings = () => {

  const [dataSource, setDataSource] = useState([]);

  useEffect(()=>{
    window.scrollTo(0, 0);
    getPayEarings()
  },[])

  useEffect(() => {}, [dataSource]);

  const getPayEarings = () => {
    let params = {
      page_size: 500,
      current_page: 1,
      start_time:'',
      end_time:'',
      sort_type: 1,
      service_type: 1,
    };
    params = utils.dealElement(params);
    window.api("agent.dailySheet", params).then((res) => {
      if (res.service_status == "S") {
        setDataSource({...res});
      }
    })
    .catch((error) => {
      setDataSource({})
    });
  };

  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} toDayDailySheet={dataSource.toDayDailySheet || {}}/>
        <PayHistory list={dataSource.list}/>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default PayEarnings;
