import React, { useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../components/TooltipGlodal";

const Details = ({ onClose, fwsSource }) => {


  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <img className={styles.codeimg} src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" alt="企业微信二维码" />
          <span className={styles.graytxt}>老八秘制小汉堡</span>
          <button className={cn("button", styles.button)}  onClick={onClose}>
          下载该商品分享码
          </button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Details;
