import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Filters from "../../components/Filters";
import Product from "../../components/Product";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import Details from "./Details";
import { useLocation } from "react-router";
import { useHistory } from "react-router";
import { Carousel } from 'antd';

// data
import { products } from "../../mocks/products";
import { followers } from "../../mocks/followers";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const ShopConfirm = () => {
  const routers = useHistory()

  const router = useLocation()
  const id = router.state ? router.state.id : '';

  const [visibleModal, setVisibleModal] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [fwsSource, setFwsSource] = useState({});
  const [detailPics, setDetailPics] = useState([]);

  useEffect(()=>{
    window.scrollTo(0, 0);
    api_getProdDetail()
    api_getFwsInfo()
  },[])

  // 支付方式 1冻结 ，2付款 3 在线支付 4 面议
  const productPay = (id, type) =>{
    if( type == 3 ){
      routers.push({
        pathname:"/products/payOrder",
        state:{
          id:id,
        }
      })
    }else if( type == 4 ){
      setVisibleModal(true)
    }
  };

  // 获取商品详情
  const api_getProdDetail = ()=>{
    let params = { id :id }
    window.api( 'goods.getgoodsdetail' , params).then(res => {
      if(res.success){
        setDataSource({ 
          ...res ,
          goods_details:(res.goods_details)?.replace(/<[^>]+>/g, ''),
          goods_intro:(res.goods_intro)?.replace(/<[^>]+>/g, ''),
          goods_intro_pics:(res.goods_intro_pics)?.split(',')
        })
        let str = res.goods_detail_pics
        let arr = str?.split(',') || []
        setDetailPics([...arr])
      }else{
        setDataSource({})
      }
    }).catch(() => {
      setDataSource({})
    });
  }

  // 代理商信息
  const api_getFwsInfo = ()=>{
    window.api( 'agent.query' , {}).then(res => {
      if(res.success){
        setFwsSource({ ...res.info })
      }else{
        setFwsSource({})
      }
    }).catch(() => {
      setFwsSource({})
    });
  }


  return (
    <>
      <div className={styles.shop}>
        <div className={styles.header}>
            <Link to="/products/shopProduct" >
              <div className={styles.toptop}>
                <img className={styles.topImg} src={`https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/logonew.png`}></img>
                <span className={styles.topSlogo}>服务市场</span>
              </div>
            </Link>
            <Link to="/products/shopOrderList" >
              <button className={cn('button', styles.btnwh)}>我的订单</button>
            </Link>
        </div>
          <Card className={styles.card} classTitle={cn("title-purple", styles.title)} title="">
                <div className={styles.flexbox}>
                  <div className={styles.picpic}>
                    <div className={styles.goodspic} >
                      <Carousel autoplay>
                        {
                          (dataSource.goods_intro_pics || []).map((item, idx) =>(
                            <img className={styles.goodspic_li} src={item} alt="商品图" ></img>
                          ))
                        }
                    </Carousel>
                    </div>
                  </div>
                  <div className={styles.flexflex}>
                    <div className={styles.line}>
                      <div className={styles.title1}>{dataSource.goods_name}</div>
                    </div>
                    <div className={styles.rating, styles.desp}>
                        <span className={styles.counter, styles.epslice}>{dataSource.goods_intro} </span>
                    </div>
                    <div className={styles.pricePrice}>
                      <div>
                        {/* <div className={styles.goodsSku}>商品价格</div> */}
                        <div className={styles.goodsPrice}>
                          {
                            dataSource.pay_type == 3 ? (
                              <>
                                <span>¥</span> {dataSource.sale_price}<span>{dataSource.valid_days >= 365 ? '/年' : ''}</span>
                              </>
                            ):(
                              <span>价格面议</span>
                            )
                          }
                        </div>
                      </div>
                      <button className={cn('button', styles.btnwh2)} onClick={ () => productPay(dataSource.id, dataSource.pay_type) }>{dataSource.pay_type == 3 ? '立即购买' : '联系我们'}</button>
                    </div>
                  </div>
                </div>
                {
                  ( detailPics.length >= 1 || dataSource.goods_details != '\n' ) ?
                  <div className={styles.detail}>
                    <div className={styles.title}>商品详情</div>
                    <div className={styles.goodsmsg}>
                      {dataSource.goods_details}
                    </div>
                  </div>
                  : null
                }
          </Card>
        {
          detailPics.length > 0 ?
          <div className={styles.background}>
            {
              (detailPics || []).map((item, idx) =>(
                <img key={idx} className={styles.imgimg} src={item} alt={detailPics.length} />
              ))
            }
          </div>
          : null
        }
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details onClose={() => setVisibleModal(false)} fwsSource={fwsSource}/>
      </Modal>
      {/* <Footer></Footer> */}
    </>
  );
};

export default ShopConfirm;
