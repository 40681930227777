import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Price.module.sass";
import Card from "../../../components/Card";
import Tooltip from "../../../components/Tooltip";
import TextInput from "../../../components/TextInput";
import Switch from "../../../components/Switch";
import Radio from "../../../components/Radio";
// import { Input, Radio } from "antd";

const Price = ({ identity, className ,priceChangeEmit,defaultData, type}) => {
//tangbin  规格对应的价格字段没有  pay_type  支付方式 1冻结 ，2付款  3 在线支付 4 面议
  const [pay_type, setResolution] = useState(defaultData.pay_type || '');
  const [cost_price,setPrice] = useState(defaultData.cost_price || '');//底价 
  const [sale_price,setSale] = useState(defaultData.sale_price || '');//售价
  const [plat_income,setPlat] = useState(defaultData.plat_income || '');//平台收益
  const [agent_income,setAgent] = useState(defaultData.agent_income || '');//服务商收益
  const [goods_num,setNum] = useState(defaultData.goods_num || '');//库存
  const [skuTags,setSkuTags] = useState(defaultData.skuTags || []); // 规格
  const [plat_cost,setCost] = useState(defaultData.plat_cost || ''); // 平台成本价
  const [agent_purchase_price,setBuy] = useState(defaultData.agent_purchase_price || ''); // 服务商采购价
  const [plat_guide,setGuide] = useState(defaultData.suggest_price || ''); // 市场指导价
  const [agent_income_rate,setRate] = useState(100); // 服务商返佣比例

  const onChangeRadio = (e) => {
    let pay_type = e
    priceChangeEmit({...{cost_price, sale_price, goods_num, skuTags}, pay_type})
    setResolution(e);
  };

  useEffect(()=>{
    setResolution(defaultData.pay_type || '')
    setSkuTags(defaultData.skuTags||[])
    setPrice(defaultData.cost_price || '')
    setSale(defaultData.sale_price || '')
    setPlat(defaultData.plat_income || '')
    setAgent(defaultData.agent_income || '')
    setNum(defaultData.goods_num || '')
    setRate(100)
    setBuy(defaultData.agent_purchase_price || '')
  },[defaultData])

  const changeText = (e,tag)=>{
    switch(tag){
      case 'price':
        priceChangeEmit({...{cost_price:e.target.value, sale_price, plat_income, agent_income, goods_num, skuTags, plat_cost, agent_purchase_price, plat_guide, agent_income_rate}, pay_type})
        setPrice(e.target.value);
        break;
      case 'sale':
        priceChangeEmit({...{cost_price, sale_price:e.target.value, plat_income, agent_income, goods_num, skuTags, plat_cost, agent_purchase_price, plat_guide, agent_income_rate}, pay_type})
        setSale(e.target.value);
        break;
      case 'plat':
        priceChangeEmit({...{cost_price, sale_price, plat_income:e.target.value, agent_income, goods_num, skuTags, plat_cost, agent_purchase_price, plat_guide, agent_income_rate}, pay_type})
        setPlat(e.target.value);
        break;
      case 'agent':
        priceChangeEmit({...{cost_price, sale_price, plat_income, agent_income:e.target.value, goods_num, skuTags, plat_cost, agent_purchase_price, plat_guide, agent_income_rate}, pay_type})
        setAgent(e.target.value);
        break;
      case 'num':
        priceChangeEmit({...{cost_price, sale_price, plat_income, agent_income, goods_num:e.target.value, skuTags, plat_cost, agent_purchase_price, plat_guide, agent_income_rate}, pay_type})
        setNum(e.target.value);
        break;
      case 'cost':
        priceChangeEmit({...{cost_price, sale_price, plat_income, agent_income, goods_num, skuTags, plat_cost:e.target.value, agent_purchase_price, plat_guide, agent_income_rate}, pay_type})
        setCost(e.target.value);
        break;
      case 'buy':
        priceChangeEmit({...{cost_price, sale_price, plat_income, agent_income, goods_num, skuTags, plat_cost, agent_purchase_price:e.target.value, plat_guide, agent_income_rate}, pay_type})
        setBuy(e.target.value);
        break;
      case 'guide':
        priceChangeEmit({...{cost_price, sale_price, plat_income, agent_income, goods_num, skuTags, plat_cost, agent_purchase_price, plat_guide:e.target.value, agent_income_rate}, pay_type})
        setGuide(e.target.value);
        break;
      case 'rate':
        priceChangeEmit({...{cost_price, sale_price, plat_income, agent_income, goods_num, skuTags, plat_cost, agent_purchase_price, plat_guide, agent_income_rate:e.target.value}, pay_type})
        setRate(e.target.value);
        break;
      default:
        let _tags = [...skuTags];
        _tags[tag].value = e.target.value;
        setSkuTags([..._tags])
        priceChangeEmit({...{cost_price, sale_price, plat_income, agent_income, goods_num, skuTags:[..._tags], plat_cost, agent_purchase_price, plat_guide, agent_income_rate}, pay_type})
        break;
    }
  }
  return (
    <Card
      className={cn(styles.card, className)}
      title="价格管理"
      classTitle="title-green"
      head={
        <div className={styles.list}>
          {
            identity != 1 ? (
              // <Radio.Group onChange={onChangeRadio} value={pay_type} size="large">
              //   {/* <Radio value={2} defaultChecked={false} disabled={true}>阶梯定价</Radio> */}
              //   <Radio value={3}>一口价</Radio>
              //   <Radio value={4}>面议</Radio>
              // </Radio.Group>
              <>
                <Radio
                  className={styles.checkbox}
                  name="confirm"
                  content='一口价'
                  value={pay_type}
                  onChange={() => onChangeRadio(3)}
                  checked={pay_type == 3}
                />
                <Radio
                  className={styles.checkbox}
                  name="confirm"
                  content='面议'
                  value={pay_type}
                  onChange={() => onChangeRadio(4)}
                  checked={pay_type == 4}
                />
              </>
              ) : null
          }
        </div>
      }
    >
      <div className={styles.price}>
        {/* <TextInput
          className={styles.field}
          label="底价"
          name="amount"
          type="number"
          min="0"
          tooltip="最小价格"
          required
          currency="¥"
          value={cost_price}
          onChange={(e)=>changeText(e,'price')}
        /> */}
        {/* <TextInput
          className={styles.field}
          label="售价"
          name="amount"
          type="number"
          min="0"
          tooltip="售卖价格"
          required
          currency="¥"
          value={sale_price}
          onChange={(e)=>changeText(e,'sale')}
        /> */}
        {
          pay_type != 4 &&
          <div className={styles.fieldset} style={{flexWrap:'wrap',marginLeft:'2px'}}>
            {/* <TextInput
              label="平台收益"
              tooltip="平台收益"
              className={styles.field3}
              name="amount"
              type="number"
              min="0"
              required
              currency="¥"
              value={plat_income}
              onChange={(e)=>changeText(e,'plat')}
            />
            <TextInput
              label="服务商收益"
              tooltip="服务商收益"
              min="0"
              className={styles.field3}
              name="amount"
              type="number"
              min="0"
              required
              currency="¥"
              value={agent_income}
              onChange={(e)=>changeText(e,'agent')}
            /> */}
          </div>
        }
        
        {
          skuTags?.length > 0 ?
          <div className={styles.fieldset} style={{flexWrap:'wrap'}}>
            {
              // skuTags?.length > 0 ? 
              // skuTags.map((item,index)=>{
              //   return <TextInput
              //     key={index}
              //     className={styles.field}
              //     classLabel={styles.label}
              //     label={"规格-"+item.text}
              //     name="minimum-amount"
              //     type="text"
              //     required
              //     currency="¥"
              //     value={item.value}
              //     onChange={(e)=>changeText(e,index)}
              //   />
              // }):null
            }
            {/* <TextInput
              className={styles.field}
              classLabel={styles.label}
              label="规格A"
              name="minimum-amount"
              type="text"
              required
              currency="¥"
              value={sku_a}
              onChange={(e)=>changeText(e,'A')}
            />
            <TextInput
              className={styles.field}
              classLabel={styles.label}
              label="规格B"
              name="suggested-amount"
              type="text"
              required
              currency="¥"
              value={sku_b}
              onChange={(e)=>changeText(e,'B')}
            /> */}
          </div>:null
        }

        {/* // 二期价格管理 */}
        {
          pay_type != 4 &&
          <div >
            <div className={styles.info1}>
              成本价
              <Tooltip
                className={styles.tooltip}
                title="成本价"
                icon="info"
                place="right"
              />
            </div>
            {
              identity != 1 ? (
                // 平台
                <div className={styles.fieldset} style={{flexWrap:'wrap',marginLeft:'2px'}}>
                  <TextInput
                    label="平台成本"
                    className={styles.field3}
                    classLabel={styles.label}
                    name="amount"
                    type="number"
                    min="0"
                    required
                    currency="¥"
                    value={cost_price}
                    onChange={(e)=>changeText(e,'price')}
                  />
                  <TextInput
                    label="服务商采购价"
                    min="0"
                    className={styles.field3}
                    classLabel={styles.label}
                    name="amount"
                    type="number"
                    min="0"
                    required
                    currency="¥"
                    value={agent_purchase_price}
                    onChange={(e)=>changeText(e,'buy')}
                  />
                </div>
              ) : (
                // 代理商
                <div className={styles.fieldset} style={{flexWrap:'wrap',marginLeft:'2px'}}>
                  <TextInput
                    label="成本价"
                    className={styles.field4}
                    classLabel={styles.label}
                    name="amount"
                    type="number"
                    min="0"
                    required
                    currency="¥"
                    value={agent_purchase_price}
                    onChange={(e)=>changeText(e,'buy')}
                  />
                  <div className={styles.zz_bg}></div>
                </div>
              )
            }
            {
              identity != 1 ? (
                <>
                  <div className={styles.info}>
                  默认售价
                    <Tooltip
                      className={styles.tooltip}
                      title="默认售价"
                      icon="info"
                      place="right"
                    />
                  </div>
                  <div className={styles.fieldset} style={{flexWrap:'wrap',marginLeft:'2px'}}>
                    <TextInput
                      label="市场指导价"
                      className={styles.field3}
                      classLabel={styles.label}
                      name="amount"
                      type="number"
                      min="0"
                      required
                      currency="¥"
                      value={sale_price}
                      onChange={(e)=>changeText(e,'sale')}
                    />

                    <div className={styles.rate_box}>
                      <TextInput
                        label="服务商返佣比例"
                        min="0"
                        className={styles.field3}
                        classLabel={styles.label}
                        name="amount"
                        type="number"
                        min="0"
                        required
                        currency="%"
                        value={agent_income_rate}
                        onChange={(e)=>changeText(e,'rate')}
                      />
                      <div className={styles.zz_bg_rate}></div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.line}>
                    <div className={styles.info}>
                    默认售价
                      <Tooltip
                        className={styles.tooltip}
                        title="默认售价"
                        icon="info"
                        place="right"
                      />
                    </div>
                    <div className={styles.info2}>
                      <Tooltip
                        className={styles.tooltip}
                        title="建议售价"
                        icon="info"
                        place="right"
                      />
                      建议售价￥{plat_guide}
                    </div>
                  </div>
                  <div className={styles.fieldset} style={{flexWrap:'wrap',marginLeft:'2px'}}>
                    <TextInput
                      label="默认售价"
                      className={styles.field3}
                      classLabel={styles.label}
                      name="amount"
                      type="number"
                      min="0"
                      required
                      currency="¥"
                      value={sale_price}
                      onChange={(e)=>changeText(e,'sale')}
                    />
                    <div className={styles.rate_box}>
                      <TextInput
                        label="返佣比例"
                        min="0"
                        className={styles.field3}
                        classLabel={styles.label}
                        name="amount"
                        type="number"
                        min="0"
                        required
                        currency="%"
                        value={agent_income_rate}
                        onChange={(e)=>changeText(e,'rate')}
                      />
                      <div className={styles.zz_bg_rate}></div>
                    </div>
                  </div>
                  <div className={styles.footertxt}>收益 = ( 默认售价 - 成本价 ）* 返佣比例</div>
                </>
              )
            }
            
            
          </div>
        }

        <div className={styles.fieldset} style={{flexWrap:'wrap',marginLeft:'2px'}}>
          {
            pay_type != 4 &&
            (
              type == 1 ? 
              (
                <TextInput
                  label="库存"
                  className={styles.field3}
                  name="amount"
                  type="number"
                  min="0"
                  currency="数量"
                  tooltip="库存"
                  required
                  value={goods_num}
                  onChange={(e)=>changeText(e,'num')}
                />
              ):
              (
                <>
                  {
                    identity != 1 ? (
                      <TextInput
                        label="库存"
                        className={styles.field3}
                        name="amount"
                        type="number"
                        min="0"
                        currency="数量"
                        tooltip="库存"
                        required
                        value={defaultData.goods_num ? defaultData.goods_num : goods_num}
                        onChange={(e)=>changeText(e,'num')}
                      />
                    ) : (
                      <div className={styles.stock_box}>
                        <TextInput
                          label="库存"
                          className={styles.field1}
                          name="amount"
                          type="number"
                          min="0"
                          currency="数量"
                          tooltip="库存"
                          required
                          value={defaultData.goods_num ? defaultData.goods_num : goods_num}
                          onChange={(e)=>changeText(e,'num')}
                        />
                        <div className={styles.zz_bg_stock}></div>
                      </div>
                    )
                  }
                </>
              )
            )
          }
          {/* {
             identity != 1 ? (
              <TextInput
                label="购买方式"
                className={styles.field2}
                name="amount"
                type="text"
                tooltip="购买方式"
                required
                value={ pay_type ? ( pay_type == 3 ? '在线支付' : '面议' ) : '' }
                disabled
              />
             ) : null
          } */}
          
        </div>

      </div>
    </Card>
  );
};

export default Price;
