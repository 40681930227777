import React, { useEffect, useState } from "react";
import styles from "./Products.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Dropdown from "../../../components/Dropdown";
import Market from "./Market";
import Table from "./Table";
import MarketShop from "./MarketShop";
import MarketCustom from "./MarketCustom";

// data
import { traffic } from "../../../mocks/traffic";
import { viewers } from "../../../mocks/viewers";
import { market } from "../../../mocks/market";
import utils from "../../../common";
import { getToDay, yesterday } from "../../../utils";
import { Empty } from "antd";

const indicatorsTraffic = [
  {
    title: "Market",
    color: "#FFBC99",
  },
  {
    title: "Social media",
    color: "#CABDFF",
  },
  {
    title: "Direct",
    color: "#B5E4CA",
  },
  {
    title: "UI8",
    color: "#B1E5FC",
  },
  {
    title: "Others",
    color: "#FFD88D",
  },
];

const indicatorsViewers = [
  {
    title: "Followers",
    color: "#B5E4CA",
  },
  {
    title: "Others",
    color: "#CABDFF",
  },
];

const Products = ( {timeType, timeDate, totalDataChange}) => {
  const navigation = ["商品报表", "服务商报表", "商户报表"];
  const [dataSource,setDataSource] = useState([]);
  const [totalData,setTotalData] = useState({});
  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
 

  useEffect(()=>{
    getDashbosrdList()
  },[activeTab,timeType,timeDate])

  useEffect(()=>{
    totalDataChange(totalData)
  },[dataSource, totalData])

  const setActiveTabChange = (x, idx)=> {
    setActiveTab(x)
  };

  // const handleSubmit = (e) => {
  //   alert();
  // };

  const getDashbosrdList = ()=> {
    let stateType = 0  // 统计类型 0 商品统计 1 服务商统计 2 商户统计
    if( activeTab == '商品报表' ){
      stateType = 0
    }else if( activeTab == '服务商报表'){
      stateType = 1
    }else if( activeTab == '商户报表'){
      stateType = 2
    }

    let params = {
      page_size: 500,
      current_page: 1,
      stat_type: stateType, 
      sort_field: 0, //排序字段 0 销量 1 销售金额 2 平台收益 3 服务商收益 4 商品单价
      sort_type: 0, // 0倒叙 1正序
      // service_type:1, // 服务类型 0 老商户 1 服务市场
    }
    if(timeType == '今日' && timeDate.length < 1){
      params.start_time = getToDay() + '00:00:00'
      params.end_time = getToDay() + '23:59:59'
    }else if(timeType == '昨日' && timeDate.length < 1){
      params.start_time = yesterday() + '00:00:00'
      params.end_time = yesterday() + '23:59:59'
    }else{
      params.start_time = timeDate[0] || getToDay() + '00:00:00'
      params.end_time = timeDate[1] || getToDay() + '23:59:59'
    }

    params = utils.dealElement(params);
    window.api('stat.list', params).then(res => {
      if(res.service_status == 'S'){
        setDataSource(res.list)
        setTotalData(res.total)
      }
    }).catch((error) => {
       setDataSource([])
       setTotalData({})
    });
  }

  return (
    <Card
      className={styles.card}
      title="销售报表"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          {/* <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onSubmit={() => handleSubmit()}
            placeholder="Search product"
            type="text"
            name="search"
            icon="search"
          /> */}
          {/* <div className={styles.control}>
            <button className={cn("button-stroke button-small", styles.button)}>
              Deleted
            </button>
            <button className={cn("button-stroke button-small", styles.button)}>
              Set status
            </button>
            <div className={styles.counter}>3 selected</div>
          </div> */}
          <div className={cn(styles.nav, "tablet-hide")}>
            {navigation.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: x === activeTab,
                })}
                onClick={() => setActiveTabChange(x, index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
          {/* <div className={cn(styles.dropdown, "tablet-show")}>
            <Dropdown
              classDropdownHead={styles.dropdownHead}
              value={activeTab}
              setValue={setActiveTab}
              options={navigation}
              small
            />
          </div> */}
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {
            dataSource.length > 0 ?
            (
              <>
                {activeTab === navigation[0] && 
                  <Market items={dataSource} />
                }
                {activeTab === navigation[1] && 
                  (
                    <MarketShop items={dataSource} />
                    // <Table title="Viewers" items={viewers} legend={indicatorsViewers} />
                  )
                }
                {activeTab === navigation[2] && 
                  (
                    <MarketCustom items={dataSource} />
                    // <Table title="Viewers" items={viewers} legend={indicatorsViewers} />
                  )
                }
              </>
            ) : (
              <div className={styles.empty}>
                <Empty />
              </div>
            )
          }
        </div>
      </div>
    </Card>
  );
};

export default Products;
