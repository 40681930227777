import { WithContext as ReactTags } from "react-tag-input";

const Tags = ({
    tagList,
    handleEmit,
    handleUpdate,
    tagFlag,
    placeholder,
    minQueryLength,
    maxLength,
    autofocus,
    allowDeleteFromEmptyInput,
    autocomplete,
    readOnly,
    allowUnique,
    allowDragDrop,
    inline,
    allowAdditionFromPaste,
    // editable,
    // clearAll
}) => {
    const KeyCodes = {
        comma: 188,
        enter: 13,
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const handleChangeTag = (val,flag) => {
        switch(flag){
            case 'del': 
                api_delProdCategory(val,tagFlag)
                break;
            case 'add': 
                api_addProdCategory(val,tagFlag)
                break;
        }
    };
    // 分类标签
    const handleDrag = (tag, currPos, newPos) => {
        const tagList = [...tagList].slice();

        tagList.splice(currPos, 1);
        tagList.splice(newPos, 0, tag);

        handleEmit(tagList,tagFlag);
    };
    //标签点击
    const handleTagClick = (i)=>{
        handleUpdate(i,tagFlag);
    }

    //清空
    // const onClearAll = () => {
    //     handleEmit([],tagFlag);
    // };

    // 编辑标签
    // const onTagUpdate = (i, newTag) => {
    //     const tagList_update = tagList.slice();
    //     tagList_update.splice(i, 1, newTag);
    //     handleEmit(tagList_update,tagFlag);
    // };


    // 删除标签
    const api_delProdCategory = (val,tagFlag) => {
        handleUpdate(val,tagFlag);
        // let params = {
        //     id: tagList[val].id
        //   }
        // window.api('goods.delcategory', params).then(res => {
        //     if(res.service_status == 'S'){
        //         handleEmit(tagList.filter((tag, index) => index !== val),tagFlag);
        //     }
        //   }).catch(() => {
            
        //   })
    };
    // 添加标签
    const api_addProdCategory = (val,tagFlag) => {
        let params = {
            goods_category_name: val.text,
            superior_id: 0,
            goods_category_hierarchy: 1,
        }
        if(tagFlag == 'systemTag'){
            params.service_type = 1
        }
        window.api('goods.addcategory', params).then(res => {
            if(res.service_status == 'S'){
                tagList.push(val);
                handleEmit(tagList,tagFlag);
            }
          }).catch(() => {
            
          })
    };


    return ( <div>
        <ReactTags
        handleDelete={(i)=>handleChangeTag(i,'del')}
        handleAddition={(val)=>handleChangeTag(val,'add')}
        handleDrag={handleDrag}
        delimiters={delimiters}
        handleTagClick={(i)=>handleTagClick(i)}
        // onClearAll={onClearAll}
        // onTagUpdate={onTagUpdate}
        suggestions={tagList}
        placeholder={placeholder || "输入回车添加分类"}
        minQueryLength={minQueryLength || 10}
        maxLength={maxLength || 10}
        autofocus={autofocus || false}
        allowDeleteFromEmptyInput={allowDeleteFromEmptyInput || true}
        autocomplete={autocomplete || true}
        readOnly={readOnly || false}
        allowUnique={allowUnique || true}
        allowDragDrop={allowDragDrop || true}
        inline={inline || true}
        inputFieldPosition="inline"
        allowAdditionFromPaste={allowAdditionFromPaste || true}
        // editable={editable}
        // clearAll={clearAll || true}
        tags={tagList}
        />
        </div>
    );
};
export default Tags;