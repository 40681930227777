import React, { useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";

const Details = ({ onClose, itemImg }) => {


  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <img className={styles.codeimg} src={itemImg} alt="" />
          <button className={cn("button", styles.button)}  onClick={onClose}>
            关闭
          </button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Details;
