import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Filters from "../../components/Filters";
import Product from "../../components/Product";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import Details from "./Details";
import { useHistory, useLocation } from "react-router";
import aes from '../../api/aes/public';
import utils from "../../common";

// data
import { products } from "../../mocks/products";
import { followers } from "../../mocks/followers";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { message } from "antd";

const PayOrder = () => {
  const router = useHistory()
  const query = useLocation()
  const id = query.state ? query.state.id : '';
  const [visibleModal, setVisibleModal] = useState(false);   
  const [toatlCount, setToatlCount] = useState(1) // 数量
  const [totalPrice, setTotalPrice] = useState('') // 
  const [dataSource, setDataSource] = useState({});
  const [fwsSource, setFwsSource] = useState({});

  useEffect(()=>{
    window.scrollTo(0, 0);
    api_getProdDetail()
    api_getFwsInfo()
  },[])

  // 获取商品详情
  const api_getProdDetail = ()=>{
    let params = { id :id }
    window.api( 'goods.getgoodsdetail' , params).then(res => {
      if(res.success){
        setDataSource({ ...res })
        setTotalPrice(res.sale_price)
      }else{
        setDataSource({})
      }
    }).catch(() => {
      setDataSource({})
    });
  }

  // 代理商信息
  const api_getFwsInfo = ()=>{
    window.api( 'agent.query' , {}).then(res => {
      if(res.success){
        setFwsSource({ ...res.info })
      }else{
        setFwsSource({})
      }
    }).catch(() => {
      setFwsSource({})
    });
  }

  const reduceHandle = ()=> {
    if(toatlCount > 1){
      setToatlCount( toatlCount - 1 )
    }
  }

  const addHandle = ()=> {
    if(toatlCount > 0){
      setToatlCount( toatlCount + 1 )
    }
  }

  const productPay = (type)=> {
    const { goods_bar_no, id } = dataSource;
    if( type == 3 ){
      let goodsInfo = [{
        goods_id: id,
        goods_qty: toatlCount,
        total_amt: totalPrice * toatlCount,
        type: 1
      }]
      let params = {
        service_type: 1,
        order_no: null,
        total_amt: totalPrice * toatlCount,
        real_amt: totalPrice * toatlCount,
        goods:JSON.stringify(goodsInfo)
      }
      params = utils.dealElement(params);
      window.api('order.createorder',params).then(res=>{
        if(res.success){
          let key = {
            order_no:res.order_no,
            total_amt: res.total_amt,
            real_amt: res.real_amt,
            payState: 1,
          }
          let key_data = aes.Encrypt(JSON.stringify(key)) // aes加密
          // let key_json = aes.Decrypt(key_aes) // aes解密
          // router.push({ pathname: '/products/checkStand?key=' + key_data })
          window.location.replace( "/products/checkStand?key=" + key_data )
        }else{
          message.error('结算失败')
        }
      })
    }else if( type == 4 ){
      setVisibleModal(true)
    }
  };


  return (
    <>
      <div className={styles.shop}>
        <div className={styles.header}>
            <Link to="/products/shopProduct" >
              <div className={styles.toptop}>
                <img className={styles.topImg} src={`https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/logonew.png`}></img>
                <span className={styles.topSlogo}>服务市场</span>
              </div>
            </Link>
            <Link to="/products/shopOrderList" >
              <button className={cn('button', styles.btnwh)}>我的订单</button>
            </Link>
        </div>
        <Card className={styles.card} classTitle={cn("title-purple", styles.title)} title="">
              <div className={styles.borderline}>
                <span>订单确认</span><span className={styles.priceblue}>订单金额：￥<span>{totalPrice * toatlCount}</span></span>
              </div>
              <div className={styles.goodsInfo}>商品信息</div>
              <div className={styles.disflex}>
                  <img className={styles.picImg} src={dataSource.goods_picture}></img>
                  <div className={styles.flexarow}>
                    <div className={styles.flexbox}>
                      <p className={styles.titlegoods}>{dataSource.goods_name}</p>
                      {/* <p className={styles.goodssku}>显示产品规格</p> */}
                    </div>
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'flex-end',height:'100%'}}>
                      <div className={styles.dis_flex}>
                        <div className={styles.del} onClick={() => reduceHandle()}>
                          <img className={styles.addImg} src={`https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/ms_icon/reduce4.png`}></img>
                        </div>
                        <span className={styles.count}>{toatlCount}</span>
                        <div className={styles.add} onClick={() => addHandle()}>
                          <img className={styles.addImg} src={`https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/ms_icon/add4.png`}></img>
                        </div>
                      </div>
                      <div className={styles.totalprice}>
                        ¥ <span>{totalPrice * toatlCount}</span>
                      </div>
                    </div>
                  </div>
              </div>
              <div className={styles.disflex_list}>
                  <div className={styles.list_li}>
                    <span>小计</span>
                    <span>¥ {totalPrice * toatlCount}</span>
                  </div>
                  <div className={styles.list_li}>
                    <span>优惠</span>
                    <span>¥ {'-' + '0'}</span>
                  </div>
                  <div className={styles.list_li}>
                    <span>运费</span>
                    <span>免费</span>
                  </div>
              </div>
              <div className={styles.disflex_list}>
                  <div className={styles.list_li2}>
                    <span>总计</span>
                    <span>¥ {totalPrice * toatlCount}</span>
                  </div>
                  <button className={cn('button', styles.btnwh2)} onClick={ () => productPay(dataSource.pay_type) }>去结账</button>
              </div>
        </Card>
        {/* <div className={styles.background}>
          <img src="https://liantuofu.com/img/lianTuoRecruit/banner.png" alt="封面图" />
        </div> */}
      </div>
      <Modal
        outerClassName={styles.outer} 
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details onClose={() => setVisibleModal(false)} fwsSource={fwsSource}/>
      </Modal>
      {/* <div className={styles.foote}>
        <Footer></Footer>
      </div> */}
    </>
  );
};

export default PayOrder;
