export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export const getTimestamp = (date) => {
  return (new Date(date)).valueOf()
}

export const getToDay = () => {
  let day = new Date();
  day.setTime( day.getTime() );
  let Y = day.getFullYear()+"-" ;
  let M = (day.getMonth()+1) + "-" ;
  let D = day.getDate();

  return Y + (M < 10 ? '0' + M : M) + (D < 10 ? '0' + D : D) + " "
}

 export const yesterday = () => {
  let day = new Date();
  day.setTime( day.getTime() - 24*60*60*1000 );
  let Y = day.getFullYear()+"-" ;
  let M = (day.getMonth()+1) + "-" ;
  let D = day.getDate();
  
  return Y + (M < 10 ? '0' + M : M) + (D < 10 ? '0' + D : D) + " "
 }

 export const getNowFormatDate = (flag) => {
    const date = new Date();
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? (`0${m}`) : m;
    let d = date.getDate();
    d = d < 10 ? (`0${d}`) : d;
    let h = date.getHours();
    h = h < 10 ? (`0${h}`) : h;
    let minute = date.getMinutes();
    let second = date.getSeconds();
    minute = minute < 10 ? (`0${minute}`) : minute;
    second = second < 10 ? (`0${second}`) : second;
    let str = '';
    switch (flag) {
      case 0:
        str = `${y}-${m}-${d} ${h}:${minute}:${second}`;
        break;
      case 1:
        str = `${y}-${m}-${d}`;
        break;
      case 2:
        str = `${m}月${d}日 ${h}:${minute}`;
        break;
      default: 
        str = `${y}-${m}-${d} ${h}:${minute}:${second}`;
    }
    return str;
}

export const getDateStr = (time , flag) => {
  const date = new Date(time);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? (`0${m}`) : m;
  let d = date.getDate();
  d = d < 10 ? (`0${d}`) : d;
  let h = date.getHours();
  h = h < 10 ? (`0${h}`) : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? (`0${minute}`) : minute;
  second = second < 10 ? (`0${second}`) : second;
  let str = '';
  switch (flag) {
    case 0:
      str = `${y}-${m}-${d} ${h}:${minute}:${second}`;
      break;
    case 1:
      str = `${y}-${m}-${d}`;
      break;
    case 2:
      str = `${m}月${d}日 ${h}:${minute}`;
      break;
    default: 
      str = `${y}-${m}-${d} ${h}:${minute}:${second}`;
  }
  return str;
}
