import React, { useState } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Details from "./Details";
// import Control from "./Control";
import Icon from "../Icon";
import Checkbox from "../Checkbox";
import { Link, useHistory } from "react-router-dom";
import Modal from "../Modal";


const Product = ({
  goodsList,
  className,
  item,
  value,
  onChange,
  released,
  withoutСheckbox,
}) => {
  const [visible, setVisible] = useState(false);
  const [hasShare, setShare] = useState(false);
  const router = useHistory()

  const handleClick = () => {
    onChange();
    setVisible(!visible);
  };
  
  const productDetail = (type, id) =>{
    router.push({
      pathname:"/products/add",
      state:{
        type:type,
        id:id,
      }
    })
  };

  const htmldesp = (item.goods_intro)?.replace(/<[^>]+>/g, '')

  const setShareChange = (flag, e) => {
    e.stopPropagation(); 
    setShare(flag)
  }

  

  return (
    <>
      <div
      className={cn(styles.product, className, { [styles.active]: visible })} onClick={ () => productDetail(2, item.id) }
    >
      <div className={styles.preview}>
        {/* {!withoutСheckbox && (
          <Checkbox
            className={styles.checkbox}
            classCheckboxTick={styles.checkboxTick}
            value={value}
            onChange={() => handleClick()}
          />
        )} */}
        {/* <Control className={styles.control} /> */}
        <img className={styles.picimg}
          srcSet={`${item.goods_picture} 2x`} 
          src={item.goods_picture} 
          alt="商品图片" 
        />
        {/* <img className={styles.share} src={'https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/share.png'} onClick={(e)=> setShareChange(true, e) } alt="" /> */}
      </div>
      <div className={styles.line}>
        <div className={styles.title}>{item.goods_name}</div>
      </div>
      {/* <div className={styles.rating}>
          <span className={styles.counter, styles.epslice}>{htmldesp}</span>
      </div> */}
      <div className={styles.rating, styles.pricePrice}>
          <span className={styles.priceTitle}>¥ <span className={styles.defaultPrice}>{item.sale_price}{item.valid_days >= 365 ? '/年' : ''}</span></span>
          
          {/* <Link> */}
            <button className={cn('button', styles.btnwh)}>编辑商品</button>
          {/* </Link> */}
      </div>

      {/* {released ? (
        <div className={styles.date}>
          <Icon name="clock" size="24" /> {item.date}
        </div>
      ) : item.ratingValue ? (
        <div className={styles.rating}>
          <Icon name="star-fill" size="24" />
          {item.ratingValue}{" "}
          <span className={styles.counter}>({item.ratingCounter})</span>
        </div>
      ) : (
        <div className={cn(styles.rating, styles.ratingEmpty)}>
          <Icon name="star-stroke" size="24" />
          No ratings
        </div>
      )} */}
    </div>
    <Modal
      outerClassName={styles.outer}
      visible={hasShare}
      onClose={() => setShare(false)}
    >
      <Details onClose={() => setShare(false)} />
    </Modal>
    </>
  );
};

export default Product;
