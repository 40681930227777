import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Filters from "../../components/Filters";
import Product from "../../components/Product";
import Icon from "../../components/Icon";
import Details from "./Details";
import Footer from "../../components/Footer";
import aes from '../../api/aes/public';

// data
import { products } from "../../mocks/products";
import { followers } from "../../mocks/followers";
import { Link } from "react-router-dom";
import utils from "../../common";
import Modal from "../../components/Modal";


const ShopProduct = () => {
  const router = useHistory()
  const query = useLocation()
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataSource,setDataSource] = useState([]);
  const [dataSource2,setDataSource2] = useState([]);
  const [fwsSource, setFwsSource] = useState({});
  
  const productSubmit = (id, type, e) =>{
    e.stopPropagation();
    if( type == 3 ){
      router.push({
        pathname:"/products/payOrder",
        state:{
          id:id,
        }
      })
    }else if( type == 4 ){
      setVisibleModal(true)
    }
  };

  const understand = (id) =>{
    router.push({
      pathname:"/products/ShopConfirm",
      state:{
        id:id
      }
    })
  };

  useEffect(()=>{
    getKey()
    window.scrollTo(0, 0);
    
  },[])

  useEffect(()=>{
    
  },[dataSource,dataSource2])

  const getKey = () => {
    if (query.search) {
      const key = utils.getQueryString(query.search).key;
      const res = JSON.parse(aes.Decrypt(key));
      loginCallback(res);
    }else{
      if(localStorage.getItem('headParams') && localStorage.getItem('PKEY')){
        getGoodsList() // 模块一
        getGoodsList2() // 模块二
        api_getFwsInfo()
      }
    }
  }

  // 登陆后的处理
  const loginCallback = (res) => {
    const headParams = {login_name: res.login_name, partner_id: res.partner_id};
    window.localStorage.setItem('fullName', res.full_name);
    window.localStorage.setItem('level', res.stock_level_id);
    window.localStorage.setItem('PKEY', res.partner_key);
    window.localStorage.setItem('identity', res.identity); // 登录者身份 0：平台 1：代理商 2：商户
    window.localStorage.setItem('headParams', JSON.stringify(headParams));
    getGoodsList() // 模块一
    getGoodsList2() // 模块二
    api_getFwsInfo()
  }


  const getGoodsList = ()=> {
    let params = {
      page_size: 500,
      current_page: 1,
      status:0, // 商品状态（0：出售中；1：已下架）
      service_type:1, // 服务类型 0 老商户 1 服务市场
      goods_name: '',
      goods_bar_no: '',
      goods_category_id: '',
      goods_category_name: '增值模块',
      goods_property: 0, // 商品类型0 全部  1软件 2硬件 3 流量
    }
    params = utils.dealElement(params);
    window.api('goods.getgoodslist', params).then(res => {
      if(res.service_status == 'S'){
          let arr = [], list = res.goods_list;

          for(var i=0; i<list.length; i++){
            list[i].goods_intro = (list[i].goods_intro)?.replace(/<[^>]+>/g, '')
            list[i].goods_details = (list[i].goods_details)?.replace(/<[^>]+>/g, '')
          }
          setDataSource([...list])
        }
    }).catch((error) => {
      setDataSource([])
    });
  }

  const getGoodsList2 = ()=> {
    let params = {
      page_size: 500,
      current_page: 1,
      status:0, // 商品状态（0：出售中；1：已下架）
      service_type:1, // 服务类型 0 老商户 1 服务市场
      goods_name: '',
      goods_bar_no: '',
      goods_category_id: '',
      goods_category_name: '营销模块',
      goods_property: 0, // 商品类型0 全部  1软件 2硬件 3 流量
    }
    params = utils.dealElement(params);
    window.api('goods.getgoodslist', params).then(res => {
      if(res.service_status == 'S'){
          let arr = [], list = res.goods_list;

          for(var i=0; i<list.length; i++){
            list[i].goods_intro = (list[i].goods_intro)?.replace(/<[^>]+>/g, '')
            list[i].goods_details = (list[i].goods_details)?.replace(/<[^>]+>/g, '')
          }
          setDataSource2([...list])
        }
    }).catch((error) => {
      setDataSource2([])
    });
  }

  // 代理商信息
  const api_getFwsInfo = ()=>{
    window.api( 'agent.query' , {}).then(res => {
      if(res.success){
        setFwsSource({ ...res.info })
      }else{
        setFwsSource({})
      }
    }).catch(() => {
      setFwsSource({})
    });
  }
  

  return (
    <>
      <div className={styles.shop}>
        <div className={styles.header}>
            <Link to="/products/shopProduct" >
              <div className={styles.toptop}>
                <img className={styles.topImg} src={`https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/logonew.png`}></img>
                <span className={styles.topSlogo}>服务市场</span>
              </div>
            </Link>
            <Link to="/products/shopOrderList" >
              <button className={cn('button', styles.btnwh)}>我的订单</button>
            </Link>
        </div>
        <div className={styles.background}>
          <img src="https://frontend-1253818706.cos.ap-shanghai.myqcloud.com/nssImages/logo/Rectangle.png" alt="封面图" />
          <div className={styles.sizebig}> 联拓 “7+N”数字化解决方案 </div>
          <div className={styles.sizesmall}>线下商业的丰富，意味着对应数字化能力的需求和服务的不同，联拓数科根据商户的行业、规模、消费场景的不同，精心打造了“7+N”的行业解决方案矩阵，为您的企业创造丰富的可能性。</div>
        </div>
        {
          dataSource?.length > 0 &&
          <Card className={styles.card} classTitle={cn("title-purple", styles.title)} title="增值模块">
            {
              dataSource.map((item,index) => (
                <div className={styles.flexbox} key={index}  onClick={() => understand(item.id)}>
                  <div className={styles.picpic}>
                    <img className={styles.goodspic} src={item.goods_picture} alt="商品图" ></img>
                  </div>
                  <div className={styles.secbox}>
                    <div className={styles.line}>
                      <div className={styles.title1}>{item.goods_name}</div>
                    </div>
                    <div className={styles.rating}>
                        <span className={styles.counter, styles.epslice}>{item.goods_intro}</span>
                    </div>
                    <div className={styles.rating, styles.pricePrice}>
                          <span className={styles.priceTitle}>
                            {
                              item.pay_type == 3 ? (
                                <>
                                  ¥ <span className={styles.defaultPrice}>{item.sale_price}</span>
                                </>
                              ) : (
                                '价格面议'
                              )
                            }
                          </span>
                          {
                            item.status == 0 && (
                              item.pay_type == 3 ? (
                                <button className={cn('button', styles.btnwh2)} onClick={ (e) => productSubmit(item.id, item.pay_type, e) }>立即购买</button>
                              ):
                              (<button className={cn('button', styles.btnwh2)} onClick={ (e) => productSubmit(item.id, item.pay_type, e) }>联系我们</button>)
                            )
                          }
                    </div>
                  </div>
                </div>
              ))
            }
          </Card>
        }
        
        {
          dataSource2?.length > 0 &&
          <Card className={styles.card} classTitle={cn("title-purple", styles.title)} title="营销模块">
            {
              dataSource2.map((item,index) => (
                <div className={styles.flexbox} key={index} onClick={() => understand(item.id)}>
                  <div className={styles.picpic}>
                    <img className={styles.goodspic} src={item.goods_picture} alt="商品图" ></img>
                  </div>
                  <div className={styles.secbox}>
                    <div className={styles.line}>
                      <div className={styles.title1}>{item.goods_name}</div>
                    </div>
                    <div className={styles.rating, styles.msg}>
                        <div>{item.goods_intro}</div>
                    </div>
                    <div className={styles.rating}>
                        {/* <ol>
                          <li className={styles.dian}>{item.goods_details}</li>
                        </ol> */}
                    </div>
                    {/* <div className={styles.rating}>
                        <div className={styles.counter, styles.epslice2}>适合: {item.goods_intro}</div>
                    </div> */}
                    <div className={styles.rating, styles.pricePrice}>
                      <span className={styles.priceTitle}>
                        {
                          item.pay_type == 3 ? (
                            <>
                              ¥ <span className={styles.defaultPrice}>{item.sale_price}</span>
                            </>
                          ) : (
                            '价格面议'
                          )
                        }
                      </span>
                      {
                        item.status == 0 && (
                          item.pay_type == 3 ? (
                            <button className={cn('button', styles.btnwh2)} onClick={ (e) => productSubmit(item.id, item.pay_type, e) }>立即购买</button>
                          ):
                          (<button className={cn('button', styles.btnwh2)} onClick={ (e) => productSubmit(item.id, item.pay_type, e) }>联系我们</button>)
                        )
                      }
                      {/* <button className={cn(styles.link, styles.under )} onClick={ (e) => productSubmit(item.id, item.pay_type, e) }>立即购买</button> */}
                    </div>
                  </div>
                </div>
              ))
            }
          </Card>
        }
        
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <Details onClose={() => setVisibleModal(false)}  fwsSource={fwsSource}/>
      </Modal>
      {/* <div className={styles.foote}>
        <Footer></Footer>
      </div> */}
    </>
  );
};

export default ShopProduct;
